{
    "LOCALIZED_ROUTES": {
        "overview": "./",
        "reviews": "avis",
        "contact": "contact",
        "about": "a-propos-de-nous",
        "offices": "bureaux",
        "location": "endroit"
    },
    "OVERVIEW": {
        "LABEL": "Introduction",
        "HEADER": "Introduction"
    },
    "REVIEWS": {
        "COUNT": "{count} avis",
        "HEADER": "Avis sur {realtor}",
        "HEADER_LOCATION": "Avis sur {location}",
        "LABEL": "Avis",
        "LABEL_SCORE_CALCULATION": "Comment le score est-il calculé ?",
        "NO_REVIEWS": {
            "DESCRIPTION": "Il n'y a pas encore assez d'avis pour calculer un score.",
            "TITLE": "Pas encore de score"
        },
        "TEXT_SCORE_CALCULATION": "Le score est basé sur tous les avis recueillis par Smooved pour {realEstateGroup}. Smooved garantit par la présente que seuls les clients ayant utilisé les services de {realEstateGroup} peuvent laisser un avis. Bien que les scores soient toujours calculés sur tous les avis, {realEstateGroup} peut choisir de ne pas afficher certains avis (par exemple parce que ces avis sont blessants ou irrespectueux).",
        "SEGMENTS": {
            "INTERVIEWEE": {
                "BUYER": "Acheteurs",
                "LANDLORD": "Propriétaires",
                "SELLER": "Vendeurs",
                "TENANT": "Locataires",
                "VISITOR": "Visiteurs",
                "GUEST": "Visiteurs"
            },
            "TITLE": "Note par segment"
        },
        "REAL_ESTATE_AGENT": "Courtier",
        "SCORE": {
            "DETRACTORS": "Détracteurs",
            "INFLUENCER_SCORE": "{score} score d'influenceur",
            "TOTAL": "Note basée sur {total} avis",
            "TITLE": {
                "EXCELLENT": "Très bon"
            },
            "PROMOTERS": "Promoteurs",
            "NEUTRALS": "Neutres",
            "MODAL": {
                "DESCRIPTION": "Ce score mesure à quel point une agence immobilière parvient à faire de ses clients de véritables ambassadeurs. Pour vous donner une meilleure idée, le score d'influenceur le plus bas possible est de -100 et le score le plus élevé possible est de +100.",
                "DESCRIPTION_TOOLTIP": "L'Influencer Score est calculé en faisant la différence entre le pourcentage de clients enthousiastes et le pourcentage de clients insatisfaits, tous deux calculés sur le nombre total de répondants.",
                "INFLUENCER_SCORE": "Influencer score: {score}",
                "TITLE": "Score d'Influenceur"
            }
        },
        "BUCKETS": {
            "TITLE": "Note"
        },
        "BACK_TO_OVERVIEW": "Retour vers l'aperçu",
        "WIDGET": {
            "SCORE": "<strong>Score moyen {score} sur 10</strong><br/> basé sur {total} avis"
        },
        "SOURCE": "Source",
        "ONLY_VERIFIED": {
            "LABEL": "Afficher uniquement les vérifications"
        },
        "VIA": {
            "OTHER": "Autres",
            "SMOOVED": "Par HouseMatch"
        },
        "WRITE_NEW_REVIEW": "Écrire un avis",
        "OTHERS": "Autres avis"
    },
    "ABOUT": {
        "HEADER": "À propos de {realtor}",
        "LABEL": "À propos de nous",
        "LABEL_SHORT": "À propos de Smooved",
        "TEXT_SHORT": "Smooved recherche la satisfaction client dans le secteur immobilier et le fait en découvrant si les clients qui viennent de conclure une transaction avec l'agent immobilier recommanderaient également les services de ce courtier à leurs amis et/ou à leur famille."
    },
    "FORM": {
        "EMAIL": {
            "LABEL": "Email"
        },
        "MESSAGE": {
            "LABEL": "Message"
        },
        "NAME": {
            "LABEL": "Nom"
        },
        "SEND": "Envoyer",
        "SUBJECT": {
            "LABEL": "Sujet"
        },
        "OFFICE": {
            "LABEL": "Agence"
        }
    },
    "SALE": "Vente",
    "RENTAL": "Location",
    "OFFICES": {
        "LABEL": "Bureaux",
        "HEADER": "Bureaux de {realtor}",
        "ALL": "Tous les bureaux",
        "SINGULAR": "Bureau"
    },
    "NOTIFICATION": {
        "DEFAULT_ERROR": "Oh oh, c'est cassé ! Réessayez.",
        "MESSAGE_SENT": "Votre message a été envoyé à { realEstateGroup } !"
    },
    "NOTES_AUTHOR": "{author} de {authorGroup}",
    "META": {
        "TITLE": "Smooved : score de satisfaction client de {score}",
        "DESCRIPTION": "Découvrez ce que nos clients pensent de nous sur notre page influenceur.",
        "TITLE_REVIEW": "{score}/10 pour {realEstateGroup}. Voir tous les avis!"
    },
    "DEFAULT_META": {
        "TITLE": "HouseMatch: Avis vérifiés",
        "TITLE_REALTORS_WITH_SCORE": "{realEstateGroup} score {score}/10 | Lire Tous les Avis",
        "TITLE_REALTORS_WITH_SCORE_LOCATION": "{realEstateGroup} score {score}/{maxScore} | Lire Tous les Avis",
        "TITLE_REALTORS_WITHOUT_SCORE": "{realEstateGroup} | Lire Tous les Avis",
        "DESCRIPTION_REALTORS_WITHOUT_SCORE": "Découvrez ce que clients ont à dire sur leur expérience avec {realEstateGroup}.",
        "DESCRIPTION_REALTORS_WITH_SCORE": "{realEstateGroup} a un score de {score}/10 sur HouseMatch. Lisez les {totalReviews} avis ou rédigez votre propre avis sur {realEstateGroup}"
    },
    "CONTACT": {
        "CONTACT_GROUP": "Contacter",
        "LABEL": "Contact",
        "SEND_A_MESSAGE": "Envoyer un message"
    },
    "INTRO": {
        "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} est une agence sans bureaux dont les activités sont principalement situées dans la Province du {provinceMainActivity}.",
            "ONE": "{name} est une agence avec 1 bureau dont les activités sont principalement situées dans la Province du {provinceMainActivity}.",
            "MORE": "{name} est une agence avec {locationCount} bureaux dont les activités sont principalement situées dans la Province du {provinceMainActivity}."
        },
        "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} est une agence sans bureau.",
            "ONE": "{name} est une agence avec 1 bureau.",
            "MORE": "{name} est une agence avec {locationCount} bureaux."
        },
        "CONTENT_SMOOVED_REVIEWS": {
            "ONE": " Sur 1 avis vérifié, ils obtiennent une note de satisfaction client de {score} sur 10, ce qui est {scoreDescription}.",
            "MORE": " Sur {total} avis vérifiés, ils obtiennent une note de satisfaction client de {score} sur 10, ce qui est {scoreDescription}."
        },
        "CONTENT_EXTERNAL_REVIEWS": " En outre, ils obtiennent une note moyenne de {score} sur 5 sur Facebook et Google."
    },
    "PROVINCE": {
        "ANT": "Anvers",
        "EFL": "Flandre-Orientale",
        "WFL": "Flandre-Occidentale",
        "FL": "Flandre",
        "VLA": "Brabant flamand",
        "LIM": "Limbourg",
        "BRU": "Bruxelles",
        "LIE": "Liège",
        "NAM": "Namur",
        "HAI": "Hainaut",
        "LUX": "Luxembourg",
        "WAL": "Brabant Wallon"
    },
    "SCORE_SCALE": {
        "BAD": "Mauvais",
        "EXCELLENT": "Excellent",
        "FAIR": "Suffisant",
        "GOOD": "Bien",
        "POOR": "Faible",
        "VERY_BAD": "Très mauvais",
        "VERY_GOOD": "Très bien"
    },
    "LOCATION_CAROUSEL": {
        "VIEW_LOCATION": "Voir l'endroit"
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuditValuePipe } from './audit-value.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { DigitPipe } from './digit.pipe';
import { DistancePipe } from './distance.pipe';
import { EmptyPipe } from './empty.pipe';
import { FormatAddressPipe } from './format-address.pipe';
import { ForceTranslatePipe } from './ftranslate.pipe';
import { IdPipe } from './get-id.pipe';
import { GreaterThanPipe } from './greater-than.pipe';
import { GroupByPipe } from './group-by.pipe';
import { HouseTypePipe } from './house-type.pipe';
import { IncludesPipe } from './includes.pipe';
import { LengthPipe } from './length.pipe';
import { LimitPipe } from './limit.pipe';
import { RoundHalfPipe } from './round-half.pipe';
import { SortPipe } from './sort.pipe';
import { TranslateCutPipe } from './translate-cut.pipe';
import { TranslateLabelPipe } from './translate-label.pipe';
import { TranslatePropPipe } from './translate-prop.pipe';
import { UpperSnakeCasePipe } from './upper-snake-case.pipe';
import { SecureAssetPipe } from './secure-asset.pipe';

const declarations = [
    HouseTypePipe,
    ForceTranslatePipe,
    SortPipe,
    CapitalizePipe,
    TranslateCutPipe,
    TranslatePropPipe,
    TranslateLabelPipe,
    LimitPipe,
    DistancePipe,
    IdPipe,
    RoundHalfPipe,
    DigitPipe,
    IncludesPipe,
    AuditValuePipe,
    GreaterThanPipe,
    GroupByPipe,
    LengthPipe,
    EmptyPipe,
];

const standalonePipes = [UpperSnakeCasePipe, FormatAddressPipe, SecureAssetPipe];

@NgModule({
    declarations: declarations,
    exports: [...declarations, ...standalonePipes],
    imports: [CommonModule, TranslateModule, ...standalonePipes],
})
export class PipeModule {}

{
    "LOCALIZED_ROUTES": {
        "overview": "./",
        "reviews": "reviews",
        "contact": "contact",
        "about": "about",
        "offices": "offices",
        "location": "location"
    },
    "OVERVIEW": {
        "HEADER": "Intro",
        "LABEL": "Intro"
    },
    "REVIEWS": {
        "COUNT": "{count} reviews",
        "LABEL": "Reviews",
        "HEADER": "{realtor} reviews",
        "HEADER_LOCATION": "{location} reviews",
        "LABEL_SCORE_CALCULATION": "How is the score calculated?",
        "NO_REVIEWS": {
            "DESCRIPTION": "There are not yet enough reviews to calculate a score.",
            "TITLE": "No score yet"
        },
        "TEXT_SCORE_CALCULATION": "The score is based on all reviews collected by Smooved for {realEstateGroup}. Smooved hereby guarantees that only customers who have made use of the services of {realEstateGroup} can leave a review. Although the scores are always calculated on all reviews, {realEstateGroup} can choose not to display certain reviews (eg because these reviews are hurtful or disrespectful).",
        "SEGMENTS": {
            "INTERVIEWEE": {
                "BUYER": "Buyers",
                "LANDLORD": "Landlords",
                "SELLER": "Sellers",
                "TENANT": "Tenants",
                "VISITOR": "Visitors",
                "GUEST": "Visitors"
            },
            "TITLE": "Score by Segment"
        },
        "REAL_ESTATE_AGENT": "Agent",
        "SCORE": {
            "DETRACTORS": "Detractors",
            "INFLUENCER_SCORE": "{score} influencer score",
            "TOTAL": "Score based on {total} reviews",
            "TITLE": {
                "EXCELLENT": "Excellent"
            },
            "PROMOTERS": "Promoters",
            "NEUTRALS": "Neutrals",
            "MODAL": {
                "DESCRIPTION": "This score measures how well a real estate agency succeeds in turning its clients into true ambassadors. To give you a better idea, the lowest possible influencer score is -100 and the highest possible score is +100.",
                "DESCRIPTION_TOOLTIP": "The Influencer Score is calculated by taking the difference between the percentage of enthusiastic customers and the percentage of dissatisfied customers, both calculated on the total number of respondents.",
                "INFLUENCER_SCORE": "Influencer score: {score}",
                "TITLE": "Influencer Score"
            }
        },
        "BUCKETS": {
            "TITLE": "Score"
        },
        "BACK_TO_OVERVIEW": "Back to overview",
        "WIDGET": {
            "SCORE": "<strong>Average score {score} on 10</strong><br/> based on {total} reviews"
        },
        "SOURCE": "Source",
        "ONLY_VERIFIED": {
            "LABEL": "Show only verified"
        },
        "VIA": {
            "OTHER": "Other",
            "SMOOVED": "Via HouseMatch"
        },
        "WRITE_NEW_REVIEW": "Write a review",
        "OTHERS": "Other reviews"
    },
    "ABOUT": {
        "LABEL": "About us",
        "HEADER": "About {realtor}",
        "LABEL_SHORT": "About Smooved",
        "TEXT_SHORT": "Smooved researches customer satisfaction in the real estate sector and does this by finding out whether customers who have just completed a transaction with the real estate agent would also recommend the services of this broker to friends and/or family."
    },
    "FORM": {
        "EMAIL": {
            "LABEL": "Email"
        },
        "MESSAGE": {
            "LABEL": "Message"
        },
        "NAME": {
            "LABEL": "Name"
        },
        "SEND": "Send",
        "SUBJECT": {
            "LABEL": "Subject"
        },
        "OFFICE": {
            "LABEL": "Office"
        }
    },
    "SALE": "Sale",
    "RENTAL": "Rental",
    "OFFICES": {
        "LABEL": "Offices",
        "HEADER": "{realtor} offices",
        "ALL": "All offices",
        "SINGULAR": "Office"
    },
    "NOTIFICATION": {
        "DEFAULT_ERROR": "Uh oh, it's broken! Try again.",
        "MESSAGE_SENT": "Your message has been sent to { realEstateGroup }!"
    },
    "NOTES_AUTHOR": "{author} from {authorGroup}",
    "META": {
        "TITLE": "Smooved: customer satisfaction score from {score}",
        "DESCRIPTION": "Find out what our customers think of us on our influencer page.",
        "TITLE_REVIEW": "{score}/10 for {realEstateGroup}. See all reviews!"
    },
    "DEFAULT_META": {
        "TITLE": "HouseMatch: Verified reviews",
        "TITLE_REALTORS_WITHOUT_SCORE": "{realEstateGroup} | Read All Reviews",
        "TITLE_REALTORS_WITH_SCORE": "{realEstateGroup} Scores {score}/10 | Read All Reviews",
        "TITLE_REALTORS_WITH_SCORE_LOCATION": "{realEstateGroup} Scores {score}/{maxScore} | Read All Reviews",
        "DESCRIPTION_REALTORS_WITHOUT_SCORE": "Find out what clients have to say about their experience with {realEstateGroup}",
        "DESCRIPTION_REALTORS_WITH_SCORE": "{realEstateGroup} has a score of {score}/10 on HouseMatch. Read all {totalReviews} reviews or write your own review about {realEstateGroup}"
    },
    "CONTACT": {
        "CONTACT_GROUP": "Contact",
        "LABEL": "Contact",
        "SEND_A_MESSAGE": "Send a message"
    },
    "INTRO": {
        "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} is a realtor without offices whose activities are mainly located in {provinceMainActivity}.",
            "ONE": "{name} is a realtor with 1 office whose activities are mainly located in {provinceMainActivity}.",
            "MORE": "{name} is a realtor with {locationCount} offices whose activities are mainly located in {provinceMainActivity}."
        },
        "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} is a realtor without offices.",
            "ONE": "{name} is a realtor with 1 office.",
            "MORE": "{name} is a realtor with {locationCount} offices."
        },
        "CONTENT_SMOOVED_REVIEWS": {
            "ONE": " On {total} verified review they achieve a customer satisfaction score of {score} out of 10, which is {scoreDescription}.",
            "MORE": " On {total} verified reviews they achieve a customer satisfaction score of {score} out of 10, which is {scoreDescription}."
        },
        "CONTENT_EXTERNAL_REVIEWS": " Moreover, they score an average of {score} out of 5 on Facebook and Google."
    },
    "PROVINCE": {
        "ANT": "Antwerp",
        "EFL": "East Flanders",
        "WFL": "West Flanders",
        "FL": "Flanders",
        "VLA": "Flemish Brabant",
        "LIM": "Limburg",
        "BRU": "Brussels",
        "LIE": "Liège",
        "NAM": "Namur",
        "HAI": "Hainaut",
        "LUX": "Luxembourg",
        "WAL": "Walloon Brabant"
    },
    "SCORE_SCALE": {
        "EXCELLENT": "Excellent",
        "VERY_GOOD": "Very good",
        "GOOD": "Good",
        "FAIR": "Fair",
        "POOR": "Poor",
        "BAD": "Bad",
        "VERY_BAD": "Very bad"
    },
    "LOCATION_CAROUSEL": {
        "VIEW_LOCATION": "View location"
    }
}

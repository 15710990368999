export enum FeatureScope {
    Gift = 'gift',
    Eots = 'eots',
    Mortgage = 'mortgage',
    Reviews = 'nps',
    Insurances = 'insurances',
    Water = 'water',
    ExternalInfluencer = 'externalInfluencer',
    Tots = 'tots',
    LeaverMeterCollection = 'leaverMeterCollection',
    Transactions = 'transactions',

    Exports = 'exports',
    ExportMoves = 'exports.moves',
    ExportRealEstateAgents = 'exports.realEstateAgents',
    ExportOffices = 'exports.offices',
    ExportRealEstateGroups = 'exports.realEstateGroups',
    ExportOperations = 'exports.operations',
    ExportInfluencers = 'exports.influencers',
    ExportMarketplace = 'exports.marketplace',
    ExportEnergyPayments = 'exports.energyPayments',
    ExportOpsActions = 'exports.opsActions',
    ExportOpsBookings = 'exports.opsBookings',
    ExportReviews = 'exports.reviews',
    ExportEscalations = 'exports.escalations',
    ExportConversions = 'exports.conversions',
    ReviewNotification = 'review.notification',
    Imports = 'imports',
    ReviewMeta = 'review.meta',
    ReviewFilterSegment = 'review.filter.segment',
    ReviewCarousel = 'review.carousel',
    ReviewNote = 'review.note',
    ReviewShare = 'review.share',
    ReviewCollect = 'review.collect',
    ReviewInsights = 'review.insights',
}

export const exportsFeatures = [
    FeatureScope.ExportMoves,
    FeatureScope.ExportOffices,
    FeatureScope.ExportOperations,
    FeatureScope.ExportRealEstateAgents,
    FeatureScope.ExportRealEstateGroups,
    FeatureScope.ExportInfluencers,
    FeatureScope.ExportEnergyPayments,
    FeatureScope.ExportOpsActions,
];

import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'secureAsset',
    standalone: true,
})
export class SecureAssetPipe implements PipeTransform {
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {}

    transform(url: string): Observable<SafeUrl> {
        return this.http.get(url, { responseType: 'blob' }).pipe(
            map((blob) => {
                const objectUrl = URL.createObjectURL(blob);
                return this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
            })
        );
    }
}

{
    "LOCALIZED_ROUTES": {
        "match-flow-start": "start",
        "match-flow-property": "uw-pand",
        "match-flow-estimation": "schatting",
        "match-flow-search-result": "makelaars",
        "match-flow-extra-info": "extra-info",
        "match-flow-contact-info": "jouw-gegevens",
        "match-flow-success": "success",
        "overview": "overzicht",
        "write-review": "schrijf-een-review",
        "match-survey-success": "success",
        "match-survey-confirm": "bevestigen",
        "match-survey-error": "fout",
        "detailed-simulation-flow-location": "locatie",
        "detailed-simulation-flow-property": "eigenschappen",
        "detailed-simulation-flow-extra-info": "extra-info",
        "detailed-simulation-flow-status": "staat",
        "detailed-simulation-flow-contact-detail": "contactgegevens",
        "detailed-simulation-flow-success": "success",
        "detailed-simulation-flow-investment": "investering",
        "email-preferences": "email-voorkeuren",
        "comparison": "vergelijk-immokantoren",
        "realtors": "immokantoren",
        "location": "locatie",
        "reviews": "beoordelingen",
        "locations": "locaties"
    },
    "EMAIL_PREFERENCES": {
        "TITLE": "HouseMatch e-mailvoorkeuren",
        "FORM": {
            "LABEL": "Ontvang e-mails over:",
            "PROPERTYVALUATIONUPDATESOPTIN": {
                "LABEL": "Prijsupdates van mijn woning"
            },
            "PROPERTIESNEARBYOPTIN": {
                "LABEL": "Vergelijkbare woningen te koop in de buurt"
            },
            "EXPERTSOPTIN": {
                "LABEL": "Interessante experts in the picture"
            },
            "NEWFEATURESOPTIN": {
                "LABEL": "Nieuwe functies op HouseMatch"
            },
            "REALESTATENEWSOPTIN": {
                "LABEL": "Nieuws over de vastgoedmarkt"
            },
            "GLOBALOPTOUT": {
                "LABEL": "Ik wil geen e-mails meer ontvangen over deze schatting"
            }
        }
    },
    "MATCH": {
        "HEADER": {
            "TITLE": "Makelaars"
        },
        "SEARCH": {
            "PLACEHOLDER": "Zoek jouw adres...",
            "BUTTON": "Zoek",
            "HOME_DESCRIPTION_TYPE": "Type woning",
            "HOME_DESCRIPTION_TYPE_SHORT": "Type",
            "SURFACE": "Oppervlakte",
            "SURFACE_SHORT": "Opp."
        },
        "SEARCH_INPUT": {
            "FIND_MATCH": "Vind best passende makelaar",
            "UPDATE": "Update zoekresultaten"
        },
        "OVERVIEW": {
            "RECENT_REVIEWS": "Recente beoordelingen",
            "SHOW_ALL": "Toon alle beoordelingen",
            "LOCATIONS": "Kantoren",
            "NEARBY_REAL_ESTATE_AGENCIES": "Andere makelaars in jouw buurt",
            "OTHER_REAL_ESTATE_AGENCIES": "Andere straffe makelaars",
            "MATCH_PERCENTAGE": "{match}% match",
            "MATCH_TOOLTIP": {
                "TITLE": "De match score wordt berekend aan de hand van:",
                "BODY_1": "De vergelijkbaarheid van de <strong>historische portefeuille</strong> van de makelaar;",
                "BODY_2": "Zijn <strong>reputatie</strong> (mix van aantal reviews en tevredenheidsscore);",
                "BODY_3": "Nabijheid van zijn <strong>kantoorlocatie</strong>."
            },
            "ABOUT_SUMMARY": {
                "TITLE": "{name} Intro",
                "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": "{name} is een makelaar {locationCount, plural, =0{zonder kantoren} one{met 1 kantoor} other{met # kantoren}} waarvan de activiteiten zich voornamelijk in {provinceMainActivity} situeren.",
                "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": "{name} is een makelaar {locationCount, plural, =0{zonder kantoren} one{met 1 kantoor} other{met # kantoren}}.",
                "CONTENT_SMOOVED_REVIEWS": " Op {total} geverifieerde {total, plural, one{review} other{reviews}} behalen zij een klanttevredenheidsscore van {score} op 10, wat {scoreDescription} is.",
                "CONTENT_EXTERNAL_REVIEWS": " Bovendien scoren ze op Facebook en Google gemiddeld {score} op 5.",
                "LOCATION_CONTENT_INTERNAL": "{locationName} is een makelaarskantoor dat deel uitmaakt van {groupName}. Ze behalen een klanttevredenheidsscore van {score} op {maxScore}, gebaseerd op {amountOfReviews} HouseMatch beoordelingen.",
                "LOCATION_CONTENT_EXTERNAL": "{locationName} is een makelaarskantoor dat deel uitmaakt van {groupName}. Ze behalen een klanttevredenheidsscore van {score} op {maxScore}, gebaseerd op {amountOfReviews} Google en Facebook beoordelingen.",
                "LOCATION_CONTENT_NO_REVIEWS": "{locationName} is een makelaarskantoor dat deel uitmaakt van {groupName}. Ze hebben momenteel nog geen beoordelingen."
            }
        },
        "MATCH_MAP": {
            "START_MATCH_FLOW.CTA": "Krijg een voorstel op maat"
        },
        "UPSELL": {
            "TITLE": "Bericht verstuurd!",
            "DESCRIPTION1": "De geselecteerde makelaars nemen spoedig contact met je op.",
            "DESCRIPTION2": "Laat ook deze andere voortreffelijke makelaars in de buurt je contacteren:",
            "CTA": "Stuur prijsaanvraag ook naar deze {count, plural, =0{makelaars} one{makelaar} other{ # makelaars}}"
        },
        "MATCH_FLOW": {
            "MATCH_FLOW_PROPERTY": "Jouw pand",
            "MATCH_FLOW_ESTIMATION": "Schatting",
            "MATCH_FLOW_SEARCH_RESULT": "Makelaars",
            "MATCH_FLOW_EXTRA_INFO": "Extra info",
            "MATCH_FLOW_CONTACT_INFO": "Jouw gegevens",
            "START": {
                "HEAD": "Laat HouseMatch je helpen met jouw",
                "BODY": "Met HouseMatch moet je niet zelf op zoek naar een makelaar. We sturen jouw gegevens door naar de makelaars die het beste bij jouw profiel passen. Zij nemen vervolgens contact met je op."
            },
            "SUCCESS": {
                "HEAD": "We hebben jouw aanvraag goed ontvangen",
                "BODY": "De door jou geselecteerde makelaars nemen spoedig contact met je op."
            },
            "PROPERTY": {
                "HEAD": "Jouw pand",
                "ADDRESS.LABEL": "Adres",
                "ABOUT_PROPERTY": {
                    "LABEL": "Over jouw pand",
                    "TYPE": "Type",
                    "FLOOR_AREA": "Bewoonbare oppervlakte (m²)",
                    "AREA": "Oppervlakte (m²)",
                    "NO_BEDROOMS": "Aantal slaapkamers",
                    "SURFACE": "Bewoonbare oppervlakte (m²)"
                }
            },
            "ESTIMATION": {
                "HEAD": "Schatting",
                "BODY_SALE": "Geef aan hoeveel je denkt dat {propertyType} ongeveer waard is in euro, zo kunnen wij jou gepaste makelaars aanraden.",
                "BODY_RENTAL": "Geef aan hoeveel je denkt dat de maandelijkse huur van {propertyType} bedraagt in euro, zo kunnen wij jou gepaste makelaars aanraden.",
                "PRICE_SALE": "Geschatte verkoopprijs",
                "PRICE_RENT": "Geschatte verhuurpijs",
                "MIN.LABEL": "Minstens",
                "MAX.LABEL": "Maximaal"
            },
            "EXTRA_INFO": {
                "HEAD": "Extra info",
                "REMARKS.LABEL": "Opmerkingen voor de makelaar"
            },
            "CONTACT_INFO": {
                "HEAD": "Jouw gegevens",
                "CONTACT": "Contact",
                "ACCEPT_TERMS_CONDITIONS.LABEL": "Ik aanvaard de | algemene voorwaarden | en de Housematch | privacy policy."
            },
            "SEARCH_RESULT": {
                "HEAD": "Makelaars",
                "BODY": "Selecteer de makelaars die jou mogen contacteren.",
                "EDIT_TITLE": "Jouw zoekresultaat verfijnen",
                "EDIT": "Verfijnen",
                "NEXT": "Ontvang voorstel van {count} makelaars",
                "NEXT_SINGLE": "Ontvang voorstel van 1 makelaar",
                "FREE": "Snel en gratis",
                "HOW_DOES_IT_WORK": {
                    "LABEL": "Hoe werkt het?",
                    "TITLE": "Hoe werkt HouseMatch?",
                    "STEP_1": "Jij selecteert makelaars die jou mogen contacteren",
                    "STEP_2": "Deze makelaars contacteren jou snel met een op maat gemaakt aanbod",
                    "STEP_3": "Jij kiest de makelaar waar jij je het beste bij voelt"
                }
            },
            "SUBMIT": "Verstuur"
        },
        "DETAILED_SIMULATION": {
            "FLOW": {
                "DETAILED_SIMULATION_FLOW_LOCATION": "Locatie",
                "DETAILED_SIMULATION_FLOW_PROPERTY": "Eigenschappen",
                "DETAILED_SIMULATION_FLOW_INVESTMENT": "Investering",
                "DETAILED_SIMULATION_FLOW_EXTRA_INFO": "Extra's",
                "DETAILED_SIMULATION_FLOW_STATUS": "Staat",
                "DETAILED_SIMULATION_FLOW_CONTACT_DETAIL": "Contactgegevens",
                "DETAILED_SIMULATION_FLOW_SUCCESS": "Resultaat",
                "SUBMIT": "Verstuur gratis schatting",
                "SUBMIT_LOW_SALE_INTENT": "Stuur een gratis schatting",
                "SUBMIT_HIGH_SALE_INTENT_CONSENT": "Stuur een gratis schatting met validatie",
                "SUBMIT_HIGH_SALE_INTENT_NO_CONSENT": "Stuur een gratis schatting zonder validatie",
                "LOCATION": {
                    "HEAD": "Locatie"
                },
                "PROPERTY": {
                    "HEAD": "Eigenschappen",
                    "TYPE.LABEL": "Pandtype",
                    "LIVING_AREA.LABEL": "Bewoonbare oppervlakte (m²)",
                    "BEDROOMS.LABEL": "Aantal slaapkamers",
                    "BATHROOMS.LABEL": "Aantal badkamers",
                    "HAS_GARDEN.LABEL": "Met tuin",
                    "GARDEN_AREA.LABEL": "Oppervlakte tuin (m²)",
                    "HAS_TERRACE.LABEL": "Met terras",
                    "TERRACE_AREA.LABEL": "Oppervlakte terras (m²)"
                },
                "INVESTMENT": {
                    "HEAD": "Investering",
                    "PURCHASE_PRICE": "Aankoop/bouwprijs",
                    "PURCHASE_YEAR": "Aankoopjaar",
                    "RENOVATED": "Ik heb gerenoveerd",
                    "RENOVATION_PRICE": "Renovatiebedrag",
                    "RENOVATION_YEAR": "Renovatiejaar"
                },
                "EXTRA_INFO": {
                    "HEAD": "Extra's - optioneel",
                    "SPACES": {
                        "LABEL": "Ruimtes",
                        "BASEMENT": "Kelder",
                        "LOFT": "Zolder",
                        "GARAGE": "Garageplaatsen",
                        "PARKING_SPACES": "Parkeerplaatsen buiten"
                    },
                    "GARDEN": {
                        "LABEL": "Tuin",
                        "ORIENTATION": {
                            "LABEL": "Orientatie",
                            "NORTH": "Noord",
                            "SOUTH": "Zuid",
                            "EAST": "Oost",
                            "WEST": "West",
                            "NORTH_EAST": "Noord-Oost",
                            "NORTH_WEST": "Noord-West",
                            "SOUTH_EAST": "Zuid-Oost",
                            "SOUTH_WEST": "Zuid-West"
                        }
                    },
                    "OTHER": {
                        "LABEL": "Andere",
                        "QUIET_NEIGHBORHOOD": "Rustige buurt",
                        "GOOD_ENERGY_VALUE": "Goede energiewaarde",
                        "SOLAR_PANELS": "Zonnepanelen"
                    }
                },
                "STATUS": {
                    "HEAD": "Staat",
                    "CONSTRUCTION_YEAR.LABEL": "Bouwjaar",
                    "PROPERTY_STATUS.LABEL": "Staat",
                    "CONSTRUCTION_MATERIAL.LABEL": "Gebruikte materialen",
                    "WHEN.OPTIONS": {
                        "STATUS": {
                            "NEW_CONSTRUCTION": "Nieuwbouw",
                            "EXCELLENT": "Uitstekend",
                            "GOOD": "Goed",
                            "RENOVATED": "Gerenoveerd",
                            "TO_REFRESH": "Op te frissen",
                            "TO_RENOVATE": "Te renoveren",
                            "TO_REBUILD": "Opnieuw te bouwen"
                        },
                        "CONSTRUCTION_MATERIAL": {
                            "PREMIUM": "Premium",
                            "QUALITATIVE": "Kwalitatief",
                            "BASIC": "Basis"
                        }
                    }
                },
                "CONTACT_DETAIL": {
                    "HEAD": "Contactgegevens",
                    "INTRO": "Via jouw telefoonnummer en e-mailadres krijg je toegang tot jouw persoonlijke schatting. Zorg dus dat deze gegevens <u>correct</u> zijn.",
                    "REALTOR_OUTREACH": {
                        "FREE_QUOTE_VALIDATION": "Laat je schatting gratis valideren",
                        "THREE_BEST_BROKERS_SUGGESTION": "Volgens ons zijn dit de 3 meest geknipte makelaars om je digitale schatting te valideren.",
                        "CHECK_BROKERS_CONTACT_ME": "Deze makelaars mogen mij contacteren voor een gratis validatie"
                    }
                },
                "SUCCESS": {
                    "HEAD": "Schatting onderweg",
                    "SUBHEAD": "Binnen enkele minuten ontvang je je schatting op: {email}",
                    "MESSAGES": {
                        "TITLE": "Wij schatten deze experts hoog in",
                        "TEXT": "Selecteer de makelaars die je mogen contacteren."
                    },
                    "RESULTS": {
                        "LABEL": "Voor een echt professionele schatting schatten wij onderstaande makelaars alvast hoog in:",
                        "CTA": "Contacteer",
                        "NO_DATA": "Geen resultaten gevonden"
                    },
                    "HIGH_SALE_INTENT": {
                        "TITLE": "Deze makelaars nemen binnenkort contact met je op"
                    }
                },
                "CONSENT": "Ik geef HouseMatch de toestemming om e-mails te versturen in het kader van het bekomen van een professionele waardebepaling van mijn woning en het bekomen van professionele ondersteuning bij de verkoop van vastgoed en aanvaard hierbij de <a class=\"u-color-primary\" href=\"https://housematch.be/nl/privacy/\" target=\"_blank\">privacy verklaring</a> van HouseMatch."
            },
            "REPORT": {
                "TITLE": "Schattingsrapport",
                "DETAILS": {
                    "TITLE": "Panddetails",
                    "TYPE": "Type",
                    "SURFACE": "Bewoonbare oppervlakte",
                    "BEDROOMS": "Aantal slaapkamers",
                    "BATHROOMS": "Aantal badkamers",
                    "BASEMENT": "Met kelder",
                    "LOFT": "Met zolder",
                    "GARAGE": "Garage",
                    "PARKING_SPACES": "Staanplaatsen buiten",
                    "SPOTS": "{count} auto's",
                    "GARDEN": "Tuin",
                    "ORIENTATION": "Orientatie tuin",
                    "QUIET_NEIGHBORHOOD": "Rustige buurt",
                    "GOOD_ENERGY_VALUE": "Goede energiescore",
                    "SOLAR_PANELS": "Zonnepanelen",
                    "CONSTRUCTION_YEAR": "Bouwjaar",
                    "RENOVATION": "Gerenoveerd",
                    "CONDITION": "Staat van de woning",
                    "MATERIAL": "Gebruikte materialen"
                },
                "TOP_EXPERIENCES": {
                    "TITLE": "Laat je leiden door recente top-ervaringen in {location}",
                    "SUBTITLE": "Vind de beste makelaar in je buurt op basis van de ervaringen van andere verkopers."
                },
                "VALUES": {
                    "TITLE": "HouseMatch waardeschatting",
                    "LOWER_LIMIT": "Ondergrens",
                    "AVERAGE": "Gemiddelde",
                    "UPPER_LIMIT": "Bovengrens",
                    "RATING_LABEL": "Ligt deze schatting binnen je verwachtingen?",
                    "RATING_INFO_PART1": "Met de gegevens die u heeft verstrekt, geloven we dat dit een nauwkeurige weergave is van de waarde van uw eigendom.",
                    "RATING_INFO_TOOLTIP": "hoog",
                    "RATING_INFO_PART2": "Contacteer een expert voor een gratis professionele schatting.",
                    "HIGH_ESTIMATION_TOOLTIP": "De betrouwbaarheid van deze schatting hangt af van het aantal vergelijkbare transacties die op deze locatie plaatvonden.",
                    "YOUR_ESTIMATION_INFO": "Je gaf aan dat deze schatting niet binnen jouw verwachting lag. Jouw schatting: ",
                    "YOUT_ESTIMATION_MODAL_LINK": "Wijzig"
                },
                "PRICE_EVOLUTION": {
                    "TITLE": "Prijsevolutie"
                },
                "BOTTOM_BANNER": {
                    "CTA": "Gratis validatie door lokale expert",
                    "HOW_DOES_IT_WORK": {
                        "LABEL": "Hoe werkt het?",
                        "STEP_1": "Laat schatting gratis valideren door lokale experts",
                        "STEP_2": "Kies uit alle makelaars uit jouw buurt",
                        "STEP_3": "Kies op basis van geverifieerde klantenreviews"
                    },
                    "EFFICIENT": "Efficiënt",
                    "TRUSTWORTHY": "Betrouwbaar",
                    "FREE": "Gratis",
                    "NON_COMMITTAL": "Vrijblijvend",
                    "INDEPENDENT": "Onafhankelijk",
                    "LOCAL": "Lokaal"
                },
                "LOCAL_EXPERTS": {
                    "HEADER": {
                        "TITLE": "Laat je schatting valideren door een lokale expert",
                        "SUBTITLE": "Hier zijn de {resultsSelected} meest geschikte experts."
                    },
                    "BROKERS_ESTIMATE_CTA": "Ontvang validatie van {resultsSelected} makelaars",
                    "SHOW_ALL": "Toon alle experts"
                },
                "PRICE_PER_SQUARE_METER": {
                    "TITLE": "Prijs per m²",
                    "AVERAGE_LABEL": "Gemiddeld {price} in {city}",
                    "PERCENTAGE_HIGHER_LABEL": "{percentage}% hoger dan gemiddeld",
                    "PERCENTAGE_LOWER_LABEL": "{percentage}% lager dan gemiddeld",
                    "SEGMENT": {
                        "HIGH": "Hogere prijsklasse",
                        "MEDIUM": "Gemiddelde prijsklasse",
                        "LOW": "Lagere prijsklasse"
                    }
                },
                "ESTIMATION_SUMMARY": {
                    "TITLE": "Jouw meerwaarde",
                    "ESTIMATION": "Schatting",
                    "PURCHASE": "Aankoop",
                    "RENOVATION": "Renovatie",
                    "GROSS_ESTIMATION_INFO": "Verwachte bruto meerwaarde",
                    "NET_ESTIMATION_INFO": "Verwachte netto meerwaarde",
                    "GROWTH_AVERAGE_PER_YEAR_INFO": "{growthAveragePerYear}% meerwaarde/jaar",
                    "NOT_CALCULATED_NET_PROFIT_INFO": "In deze berekening houden we nog geen rekening met andere kosten, zoals bijv. notariskosten, of hypotheek.",
                    "CALCULATED_NET_PROFIT_INFO": "Als je nog een openstaande hypotheek hebt, kunnen er extra kosten aangerekend worden door jouw bank bij verkoop.",
                    "OPEN_NET_PROFIT_MODAL_BUTTON": "Bereken netto meerwaarde",
                    "OPEN_NET_PROFIT_MODAL_DETAIL_LINK": "Detail"
                },
                "NET_PROFIT": {
                    "TITLE": "Bereken netto meerwaarde",
                    "TEXT_INFO": "Als je nog een openstaande hypotheek hebt, kunnen er extra kosten aangerekend worden door jouw bank bij verkoop.",
                    "OUTSTANDING_AMOUNT_LABEL": "Openstaand bedrag",
                    "INTEREST_RATE_LABEL": "Interestvoet",
                    "BANKING_APP_INFO": "Je vindt dit in je banking-app.",
                    "SELLING_COST_LABEL": "3% verkoopskost",
                    "SELLING_COST_TOOLTIP": "Geschatte kost die verband houdt met de verkoop van de woning.",
                    "REINVESTMENT_COMPENSATION_LABEL": "Wederbeleggingsvergoeding",
                    "REINVESTMENT_COMPENSATION_TOOLTIP": "Kost die verband houdt met de vroegtijdige terugbetaling van jouw nog uitstaande hypothecaire lening aan de bank die jou het krediet verschaft heeft.",
                    "MORTGAGE_RELIEF_LABEL": "Handlichting hypotheek",
                    "MORTGAGE_RELIEF_TOOLTIP": "Notariële kost die verband houdt met het het opheffen van de kredietgarantie op jouw woning die samenhangt met het hypothecaire krediet dat werd afgesloten voor het aankopen van de woning.",
                    "SALE_FILE_COST_LABEL": "Kosten verkoopdossier",
                    "SALE_FILE_COST_TOOLTIP": "Kosten die verbandhouden met het opmaken van een verkoopsdossier.",
                    "TOTAL_COSTS_LABEL": "Totaal kosten",
                    "EXPECTED_NET_CAPITAL_GAIN_LABEL": "Verwachte netto meerwaarde",
                    "CANCEL_BUTTON": "Annuleer",
                    "CONFIRM_BUTTON": "Bewaar",
                    "SUBMIT_SUCCESS_MESSAGE": "Schattingsverslag geüpdatet."
                },
                "ESTIMATION_FEEDBACK": {
                    "ESTIMATE_OPTIONS_LABEL": "Wat vond je van deze schatting?",
                    "DESIRED_AMOUNT_LABEL": "Hoeveel had je zelf in gedachte?",
                    "WHY_LABEL": "Waarom?",
                    "NEGATIVE_TITLE": "De schatting ligt niet binnen je verwachtingen",
                    "POSITIVE_TITLE": "De schatting ligt binnen je verwachtingen",
                    "SEND_FEEDBACK_BUTTON": "Verstuur feedback",
                    "OPTIONS_RATE": {
                        "WAY_TOO_HIGH": "Veel te hoog",
                        "TOO_HIGH": "Te hoog",
                        "ACCURATE": "Accuraat",
                        "TOO_LOW": "Te laag",
                        "WAY_TOO_LOW": "Veel te laag"
                    },
                    "SUBMIT_SUCCESS_MESSAGE": "Bedankt voor jouw feeback!"
                }
            }
        },
        "MESSAGES": {
            "START_MATCH_FLOW": {
                "HEAD": "Laat makelaars jou contacteren <br />met een voorstel op maat",
                "BODY": "Laat jouw gegevens achter en wij doen de rest!",
                "CTA": "Start met HouseMatch"
            }
        },
        "FILTER": {
            "RELEVANCE": {
                "ALL": "Alle makelaars",
                "RELEVANCE": "Relevante makelaars"
            },
            "MINIMUM_SCORE": {
                "0": "Alle scores",
                "5": "5+",
                "6": "6+",
                "7": "7+",
                "8": "8+",
                "9": "9+",
                "10": "10",
                "LABEL": "Score"
            }
        },
        "MATCH_SURVEY": {
            "SUCCESS": {
                "HEAD": "Bedankt voor je beoordeling!",
                "BODY": "Je beoordeling werd gepubliceerd op de HouseMatch pagina van {realEstateGroup}.",
                "CTA": "Bekijk je beoordeling"
            },
            "CONFIRM": {
                "HEAD": "Check je e-mail!",
                "BODY": "We hebben je een bevestigings-e-mail gestuurd. Klik op de link in de e-mail om je beoordeling te publiceren."
            },
            "ERROR": {
                "HEAD": "Oh nee!",
                "BODY": "Er ging iets mis tijdens de verwerking van uw verzoek.",
                "CTA": "Terug naar HouseMatch"
            }
        },
        "MULTI_SELECT_CONTACT_BANNER": {
            "BROKERS_ESTIMATE_CTA": "Ontvang prijs van {numberOfSelectedBrokers} makelaars",
            "REFINE_RESULTS_CTA": "Verfijn resultaten",
            "FAST_AND_FREE": "Snel en gratis",
            "HOW_DOES_IT_WORK": {
                "CTA": "Hoe werkt het?",
                "INSTRUCTIONS_PICK_BROKER": "Kies uit alle makelaars van België",
                "INSTRUCTIONS_CUSTOMER_REVIEW": "Baseer je op geverifieerde klantenreviews",
                "INSTRUCTIONS_BROKERS_CONTACT": "Laat je contacteren door één of meerdere topmakelaars"
            }
        },
        "SCHEDULE": {
            "APPOINTMENT": {
                "BODY": "We volgen gratis jouw hele traject mee op tot aan de verkoop. Laat ons weten wanneer het past, en we bellen je op.",
                "HEAD": "Kies wanneer we jou mogen contacteren",
                "ADDRESS": "Jouw woning"
            }
        },
        "MATCH_SCORE": {
            "GROUP_REVIEWS": "Deze score is gebaseerd op reviews voor de groep van vastgoedkantoren waartoe dit kantoor behoort omdat we niet voldoende reviews hebben voor dit kantoor.",
            "NO_REVIEWS": "We hebben niet voldoende reviews om een score te berekenen."
        }
    },
    "COMPARISON": {
        "MUNICIPALITY": {
            "PAGE_TITLE": "Vergelijk makelaars en immokantoren in {zipCode} {city}",
            "HEADER": "Vergelijk <span class=\"u-marked-text-tertiary\">makelaars</span> en <span class=\"u-marked-text-primary u-font-style-italic\">immokantoren</span> in {zipCode} {city}",
            "CHANGE": "Wijzig gemeente",
            "SELECTION": {
                "TITLE": "Een greep uit de beste makelaars in {city}",
                "LABEL": "Ben je op zoek naar een betrouwbaar immokantoor in {city} ({zipCode}), maar wil je je keuze baseren op de ervaringen van anderen? Hieronder vind je een vergelijking van de immokantoren in {city}, gebaseerd op het hoogste aantal reviews in heel België."
            },
            "TOP_REALTORS.CTA": "Contacteer de top 5 makelaars",
            "NOT_FOUND": {
                "TITLE": "Heeft u niet gevonden wat u zocht?",
                "LABEL": "Geen zorgen! Vind de beste makelaars bij u in de buurt.",
                "CTA": "Vind de beste makelaars bij mij in de buurt"
            },
            "FREE_ESTIMATION": {
                "TITLE": "Schat gratis de waarde van je woning",
                "LABEL": "Met HouseMatch kan je gratis, online en accuraat de waarde van je woning laten schatten.Vul enkele gegevens in en binnen enkele seconden heb je een waardebepaling die ook nogeens gratis kan laten verifieren door de beste makelaars in de buurt.",
                "CTA": "Start gratis online schatting",
                "LINK": "https://app.housematch.be/nl/detailed-simulation/locatie"
            },
            "LOCATIONS": {
                "IN_ZIP_CODE": "Deze makelaars hebben een fysiek kantoor in { city }",
                "IN_AREA": "Deze makelaars hebben { city } als werkingsgebied, zonder er een kantoor te hebben",
                "NON_VERIFIED": "Andere makelaars uit { city } die we niet konden verifiëren maar wel veel Google reviews hebben"
            },
            "TOP_REVIEWS": {
                "TITLE": "Top-ervaringen met makelaars in {city}",
                "LABEL": "Vind de beste makelaar in je buurt op basis van de ervaringen van andere verkopers."
            },
            "OTHER_LOCATIONS": " + {amount} {amount, plural, =0{andere} one{ander} other{andere}} {amount, plural, =0{kantoren} one{kantoor} other{kantoren}} op deze locatie",
            "NO_RESULT": "Geen resultaten gevonden op deze locatie",
            "META": {
                "TITLE": "Vergelijk makelaars en immokantoren in { city }",
                "DESCRIPTION": "De beste makelaars en immokantoren in { city } vind je op HouseMatch. Vergelijk vastgoedmakelaars in { city } op basis van échte reviews."
            }
        },
        "OVERVIEW": {
            "PAGE_TITLE": "Vind een makelaar in je buurt",
            "PAGE_DESCRIPTION": "Ben je op zoek naar een betrouwbare makelaar bij jou in de buurt, maar wil je je keuze baseren op ervaringen van anderen? Hieronder vind je een vergelijkende lijst van makelaars per stad, gebaseerd op het hoogste aantal beoordelingen in heel België.",
            "HEADER": "Vind een <span class=\"u-marked-text-tertiary\">makelaar</span> in je buurt",
            "PROVINCE": "Provincie {province}"
        },
        "ALPHABETICAL": {
            "HEADER": "Alle <span class=\"u-font-style-italic u-marked-text-primary\">makelaars</span> op een rij",
            "PAGE_TITLE": "Alle makelaars op een rij",
            "PAGE_DESCRIPTION": "Ben je op zoek naar een betrouwbare makelaar bij jou in de buurt, maar wil je je keuze baseren op ervaringen van anderen? Hieronder vind je een vergelijkingslijst van makelaars op een rij, in heel België."
        },
        "ALPHABETICAL_LOCATIONS": {
            "HEADER": "Alle <span class=\"u-font-style-italic u-marked-text-primary\">makelaar kantoren</span> op een rij",
            "PAGE_TITLE": "Alle makelaar kantoren op een rij",
            "PAGE_DESCRIPTION": "Ben je op zoek naar een betrouwbare makelaar bij jou in de buurt, maar wil je je keuze baseren op ervaringen van anderen? Hieronder vind je een vergelijkingslijst van makelaars op een rij, in heel België."
        }
    },
    "REALTOR": {
        "NOT_FOUND": {
            "TITLE": "Heeft u niet gevonden wat u zocht?",
            "LABEL": "Geen zorgen! Vind de beste makelaars bij u in de buurt.",
            "CTA": "Vind de beste makelaars in de buurt"
        }
    }
}

{
    "LOCALIZED_ROUTES": {
        "overview": "./",
        "reviews": "beoordelingen",
        "contact": "contact",
        "about": "over-ons",
        "offices": "kantoren",
        "location": "locatie"
    },
    "OVERVIEW": {
        "LABEL": "Intro",
        "HEADER": "Intro"
    },
    "REVIEWS": {
        "COUNT": "{count} beoordelingen",
        "HEADER": "{realtor} beoordelingen",
        "HEADER_LOCATION": "{location} beoordelingen",
        "LABEL": "Reviews",
        "LABEL_SCORE_CALCULATION": "Hoe wordt de score berekend?",
        "TEXT_SCORE_CALCULATION": "De score is gebaseerd op alle reviews die door Smooved voor {realEstateGroup} werden verzameld. Hierbij garandeert Smooved dat enkel klanten die een beroep hebben gedaan op de diensten van {realEstateGroup} een review kunnen achterlaten. Hoewel de scores steeds berekend worden op alle reviews, kan {realEstateGroup} er voor kiezen bepaalde reviews niet te laten weergeven (bijv. omdat deze reviews kwetsend of onrespectvol zijn).",
        "SCORE": {
            "DETRACTORS": "Tegenstanders",
            "NEUTRALS": "Neutrale meningen",
            "PROMOTERS": "Promoters",
            "TITLE": {
                "EXCELLENT": "Zeer goed"
            },
            "TOTAL": "Score gebaseerd op {total} reviews",
            "INFLUENCER_SCORE": "{score} influencer score",
            "MODAL": {
                "TITLE": "Influencer Score",
                "INFLUENCER_SCORE": "Influencer score: {score}",
                "DESCRIPTION": "Deze score peilt naar hoe goed een vastgoedkantoor er in slaagt om van zijn klanten echte ambassadeurs te maken. Om je een beter idee te geven, de laagst mogelijke influencer score is -100 en de hoogst mogelijk score is +100.",
                "DESCRIPTION_TOOLTIP": "De Influencer Score wordt berekend door het verschil te nemen tussen het percentage enthousiaste klanten en het percentage ontevreden klanten beide berekend op het totaal aantal ondervraagden."
            }
        },
        "BACK_TO_OVERVIEW": "Terug naar het overzicht",
        "BUCKETS": {
            "TITLE": "Score"
        },
        "REAL_ESTATE_AGENT": "Makelaar",
        "SEGMENTS": {
            "TITLE": "Score per Segment",
            "INTERVIEWEE": {
                "BUYER": "Kopers",
                "SELLER": "Verkopers",
                "TENANT": "Huurders",
                "LANDLORD": "Verhuurders",
                "VISITOR": "Bezoekers",
                "GUEST": "Bezoekers"
            }
        },
        "WIDGET": {
            "SCORE": "<strong>Gemiddelde score {score} op 10</strong><br/>gebaseerd op {total} reviews"
        },
        "NO_REVIEWS": {
            "TITLE": "Nog geen score",
            "DESCRIPTION": "Er zijn nog niet genoeg reviews om een score te berekenen."
        },
        "VIA": {
            "SMOOVED": "Via HouseMatch",
            "OTHER": "Andere"
        },
        "SOURCE": "Bron",
        "ONLY_VERIFIED": {
            "LABEL": "Toon alleen geverifieerde"
        },
        "WRITE_NEW_REVIEW": "Schrijf een review",
        "OTHERS": "Andere reviews"
    },
    "ABOUT": {
        "HEADER": "Over {realtor}",
        "LABEL": "Over ons",
        "LABEL_SHORT": "Over Smooved",
        "TEXT_SHORT": "Smooved onderzoekt klanttevredenheid in de vastgoedsector en doet dit door na te gaan of klanten die net een transactie met de vastgoedmakelaar hebben afgerond, de diensten van deze makelaar ook zouden aanbevelen aan vrienden en/of familie."
    },
    "OFFICES": {
        "HEADER": "{realtor} kantoren",
        "LABEL": "Kantoren",
        "ALL": "Alle kantoren",
        "SINGULAR": "Kantoor"
    },
    "CONTACT": {
        "LABEL": "Contact",
        "CONTACT_GROUP": "Contacteer",
        "SEND_A_MESSAGE": "Stuur een bericht"
    },
    "FORM": {
        "NAME": {
            "LABEL": "Naam"
        },
        "EMAIL": {
            "LABEL": "E-mail"
        },
        "SUBJECT": {
            "LABEL": "Onderwerp"
        },
        "MESSAGE": {
            "LABEL": "Bericht"
        },
        "OFFICE": {
            "LABEL": "Kantoor"
        },
        "SEND": "Verzenden"
    },
    "SALE": "Verkoop",
    "RENTAL": "Verhuur",
    "NOTIFICATION": {
        "DEFAULT_ERROR": "Uh oh, 't is kapot! Probeer nog eens.",
        "MESSAGE_SENT": "Uw bericht werd verstuurd naar { realEstateGroup }!"
    },
    "NOTES_AUTHOR": "{author} van {authorGroup}",
    "META": {
        "TITLE": "Smooved: klanttevredenheidsscore van {score}",
        "DESCRIPTION": "Ontdek wat onze klanten van ons vinden op onze influencer pagina.",
        "TITLE_REVIEW": "{score}/10 voor {realEstateGroup}. Bekijk alle reviews!"
    },
    "DEFAULT_META": {
        "TITLE": "HouseMatch: Geverifiëerde reviews",
        "TITLE_REALTORS_WITH_SCORE": "{realEstateGroup} Scoort {score}/10 | Lees Alle Reviews",
        "TITLE_REALTORS_WITH_SCORE_LOCATION": "{realEstateGroup} Scoort {score}/{maxScore} | Lees Alle Reviews",
        "TITLE_REALTORS_WITHOUT_SCORE": "{realEstateGroup} | Lees Alle Reviews",
        "DESCRIPTION_REALTORS_WITHOUT_SCORE": "Kom te weten wat klanten te vertellen hebben over hun ervaring met {realEstateGroup}.",
        "DESCRIPTION_REALTORS_WITH_SCORE": "{realEstateGroup} heeft een score van {score}/10 op HouseMatch. Lees alle {totalReviews} reviews of schrijf zelf een review over {realEstateGroup}"
    },
    "INTRO": {
        "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} is een makelaar zonder kantoren waarvan de activiteiten zich voornamelijk in {provinceMainActivity} situeren.",
            "ONE": "{name} is een makelaar met 1 kantoor waarvan de activiteiten zich voornamelijk in {provinceMainActivity} situeren.",
            "MORE": "{name} is een makelaar met {locationCount} kantoren waarvan de activiteiten zich voornamelijk in {provinceMainActivity} situeren."
        },
        "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": {
            "ZERO": "{name} is een makelaar zonder kantoren.",
            "ONE": "{name} is een makelaar met 1 kantoor.",
            "MORE": "{name} is een makelaar met {locationCount} kantoren."
        },
        "CONTENT_SMOOVED_REVIEWS": {
            "ONE": " Op {total} geverifieerde review behalen zij een klanttevredenheidsscore van {score} op 10, wat {scoreDescription} is.",
            "MORE": " Op {total} geverifieerde reviews behalen zij een klanttevredenheidsscore van {score} op 10, wat {scoreDescription} is."
        },
        "CONTENT_EXTERNAL_REVIEWS": " Bovendien scoren ze op Facebook en Google gemiddeld {score} op 5."
    },
    "PROVINCE": {
        "ANT": "Antwerpen",
        "EFL": "Oost-Vlaanderen",
        "WFL": "West-Vlaanderen",
        "FL": "Vlaanderen",
        "VLA": "Vlaams-Brabant",
        "LIM": "Limburg",
        "BRU": "Brussel",
        "LIE": "Luik",
        "NAM": "Namen",
        "HAI": "Henegouwen",
        "LUX": "Luxemburg",
        "WAL": "Waals-Brabant"
    },
    "SCORE_SCALE": {
        "EXCELLENT": "Uitstekend",
        "VERY_GOOD": "Zeer goed",
        "GOOD": "Goed",
        "FAIR": "In orde",
        "POOR": "Zwak",
        "BAD": "Slecht",
        "VERY_BAD": "Zeer slecht"
    },
    "LOCATION_CAROUSEL": {
        "VIEW_LOCATION": "Bekijk locatie"
    }
}

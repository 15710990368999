{
    "LOCALIZED_ROUTES": {
        "match-flow-start": "start",
        "match-flow-property": "your-property",
        "match-flow-success": "success",
        "match-flow-contact-info": "your-information",
        "match-flow-estimation": "estimation",
        "match-flow-extra-info": "extra-info",
        "match-flow-search-result": "agents",
        "overview": "overview",
        "write-review": "write-a-review",
        "match-survey-success": "success",
        "match-survey-confirm": "confirm",
        "match-survey-error": "error",
        "detailed-simulation-flow-location": "address",
        "detailed-simulation-flow-property": "features",
        "detailed-simulation-flow-status": "status",
        "detailed-simulation-flow-contact-detail": "contact-detail",
        "detailed-simulation-flow-success": "success",
        "detailed-simulation-flow-extra-info": "extra-info",
        "detailed-simulation-flow-investment": "investment",
        "email-preferences": "email-preferences",
        "comparison": "compare-realtors",
        "realtors": "realtors",
        "location": "location",
        "reviews": "reviews",
        "locations": "locations"
    },
    "MATCH": {
        "MATCH_FLOW": {
            "PROPERTY": {
                "ABOUT_PROPERTY": {
                    "SURFACE": "Habitable surface (m²):",
                    "AREA": "Surface (m²)",
                    "FLOOR_AREA": "Living area (m²)",
                    "LABEL": "About your property",
                    "NO_BEDROOMS": "Number of bedrooms",
                    "TYPE": "Type"
                },
                "ADDRESS.LABEL": "Address",
                "HEAD": "Your property"
            },
            "CONTACT_INFO": {
                "ACCEPT_TERMS_CONDITIONS.LABEL": "I accept the | terms and conditions | and the  | privacy policy | of Housematch.",
                "CONTACT": "Contact",
                "HEAD": "Your details"
            },
            "ESTIMATION": {
                "BODY_RENTAL": "Please indicate how much you think the monthly rent of {propertyType} is in Euros, that way we can recommend appropriate brokers.",
                "BODY_SALE": "Please indicate how much you think {propertyType} is worth approximately in euros, that way we can recommend appropriate brokers.",
                "HEAD": "Estimation",
                "MAX.LABEL": "Maximum",
                "MIN.LABEL": "Minimum",
                "PRICE_RENT": "Estimated rental price",
                "PRICE_SALE": "Estimated selling price"
            },
            "MATCH_FLOW_CONTACT_INFO": "Your details",
            "MATCH_FLOW_ESTIMATION": "Estimation",
            "MATCH_FLOW_EXTRA_INFO": "Extra info",
            "MATCH_FLOW_PROPERTY": "Your property",
            "MATCH_FLOW_SEARCH_RESULT": "Brokers",
            "EXTRA_INFO": {
                "HEAD": "More info",
                "REMARKS.LABEL": "Remarks for the brokers"
            },
            "SEARCH_RESULT": {
                "BODY": "Select the brokers that may contact you.",
                "EDIT": "Refine",
                "EDIT_TITLE": "Refine your search results",
                "FREE": "Quick and free",
                "HEAD": "Brokers",
                "HOW_DOES_IT_WORK": {
                    "LABEL": "How does it work",
                    "STEP_1": "You select the brokers that may contact you",
                    "STEP_2": "These brokers contact you quickly with a customized offer",
                    "STEP_3": "You choose the broker you feel most comfortable with.",
                    "TITLE": "How does HouseMatch work?"
                },
                "NEXT": "Receive a proposal from {count} brokers",
                "NEXT_SINGLE": "Get a proposal from 1 broker"
            },
            "START": {
                "BODY": "With HouseMatch, you don't have to look for a realtor yourself. We send your details to the estate agents who best match your profile. They will then contact you.",
                "HEAD": "Let HouseMatch help you with your"
            },
            "SUBMIT": "Send",
            "SUCCESS": {
                "BODY": "The brokers you have selected will contact you soon.",
                "HEAD": "We have well received your request."
            }
        },
        "DETAILED_SIMULATION": {
            "FLOW": {
                "CONSENT": "I authorize HouseMatch to send emails in the context of obtaining a professional valuation of my home and obtaining professional assistance with the sale of real estate and hereby accept HouseMatch's <a class=\"u-color-primary\" href=\"https://housematch.be/nl/privacy/\" target=\"_blank\">privacy statement</a>.",
                "CONTACT_DETAIL": {
                    "HEAD": "Contact details",
                    "INTRO": "Your phone number and email address will be used to access your personal estimation. So make sure these data are <u>correct</u>.",
                    "REALTOR_OUTREACH": {
                        "FREE_QUOTE_VALIDATION": "Get your estimate validated for free",
                        "THREE_BEST_BROKERS_SUGGESTION": "In our opinion, these are the 3 most clipped brokers to validate your digital estimate.",
                        "CHECK_BROKERS_CONTACT_ME": "These brokers may contact me for a free validation"
                    }
                },
                "DETAILED_SIMULATION_FLOW_CONTACT_DETAIL": "Contact details",
                "DETAILED_SIMULATION_FLOW_EXTRA_INFO": "Extras",
                "DETAILED_SIMULATION_FLOW_LOCATION": "Address",
                "DETAILED_SIMULATION_FLOW_PROPERTY": "Features",
                "DETAILED_SIMULATION_FLOW_INVESTMENT": "Investment",
                "DETAILED_SIMULATION_FLOW_STATUS": "Condition",
                "DETAILED_SIMULATION_FLOW_SUCCESS": "Result",
                "EXTRA_INFO": {
                    "GARDEN": {
                        "LABEL": "Garden",
                        "ORIENTATION": {
                            "EAST": "East",
                            "LABEL": "Orientation",
                            "NORTH": "North",
                            "NORTH_EAST": "North-East",
                            "NORTH_WEST": "North-West",
                            "SOUTH": "South",
                            "SOUTH_EAST": "South-East",
                            "SOUTH_WEST": "South-West",
                            "WEST": "West"
                        }
                    },
                    "HEAD": "Extras - optional",
                    "OTHER": {
                        "GOOD_ENERGY_VALUE": "Good energy value",
                        "LABEL": "Other",
                        "QUIET_NEIGHBORHOOD": "Quiet neighborhood",
                        "SOLAR_PANELS": "Solar panels"
                    },
                    "SPACES": {
                        "BASEMENT": "Basement",
                        "GARAGE": "Garage spots",
                        "LABEL": "Spaces",
                        "LOFT": "Attic",
                        "PARKING_SPACES": "Outside parking spots"
                    }
                },
                "LOCATION": {
                    "HEAD": "Address"
                },
                "PROPERTY": {
                    "BATHROOMS.LABEL": "Number of bathrooms",
                    "BEDROOMS.LABEL": "Number of bedrooms",
                    "GARDEN_AREA.LABEL": "Garden surface (m²)",
                    "HAS_GARDEN.LABEL": "With garden",
                    "TERRACE_AREA.LABEL": "Terrace surface (m²)",
                    "HAS_TERRACE.LABEL": "With terrace",
                    "HEAD": "Features",
                    "LIVING_AREA.LABEL": "Habitable area (m²)",
                    "TYPE.LABEL": "Property type"
                },
                "INVESTMENT": {
                    "HEAD": "Investment",
                    "PURCHASE_PRICE": "Purchase/construction price",
                    "PURCHASE_YEAR": "Purchase year",
                    "RENOVATED": "I renovated",
                    "RENOVATION_PRICE": "Renovation amount",
                    "RENOVATION_YEAR": "Renovation year"
                },
                "STATUS": {
                    "CONSTRUCTION_MATERIAL.LABEL": "Used materials",
                    "CONSTRUCTION_YEAR.LABEL": "Construction year",
                    "HEAD": "Condition",
                    "PROPERTY_STATUS.LABEL": "Condition",
                    "WHEN.OPTIONS": {
                        "CONSTRUCTION_MATERIAL": {
                            "BASIC": "Basic",
                            "PREMIUM": "Premium",
                            "QUALITATIVE": "Quality"
                        },
                        "STATUS": {
                            "NEW_CONSTRUCTION": "New construction",
                            "EXCELLENT": "Excellent",
                            "GOOD": "Good",
                            "RENOVATED": "Renovated",
                            "TO_REFRESH": "To refresh",
                            "TO_RENOVATE": "To renovate",
                            "TO_REBUILD": "To rebuild"
                        }
                    }
                },
                "SUBMIT": "Send a free estimation",
                "SUBMIT_LOW_SALE_INTENT": "Send a free estimation",
                "SUBMIT_HIGH_SALE_INTENT_CONSENT": "Send a free estimate with validation",
                "SUBMIT_HIGH_SALE_INTENT_NO_CONSENT": "Send a free estimate without validation",
                "SUCCESS": {
                    "HEAD": "Estimation on the way",
                    "RESULTS": {
                        "CTA": "Contact",
                        "LABEL": "For a truly professional estimation, we already rate the brokers below highly:",
                        "NO_DATA": "No results found"
                    },
                    "SUBHEAD": "In a few minutes, you will receive your estimation on the following email addresse: {email} ",
                    "MESSAGES": {
                        "TEXT": "Select the realtors who may contact you.",
                        "TITLE": "Wij schatten deze experts hoog in"
                    },
                    "HIGH_SALE_INTENT": {
                        "TITLE": "These brokers will contact you shortly"
                    }
                }
            },
            "REPORT": {
                "BOTTOM_BANNER": {
                    "CTA": "Free validation by a local expert",
                    "EFFICIENT": "Efficient",
                    "LOCAL": "Local",
                    "FREE": "Free",
                    "HOW_DOES_IT_WORK": {
                        "LABEL": "How does it work?",
                        "STEP_1": "Get estimation validated for free by local experts",
                        "STEP_2": "Choose from all the realtors in your area",
                        "STEP_3": "Choose based on verified customer reviews"
                    },
                    "INDEPENDENT": "Independent",
                    "NON_COMMITTAL": "No obligations",
                    "TRUSTWORTHY": "Reliable"
                },
                "TOP_EXPERIENCES": {
                    "TITLE": "Be guided by recent top experiences in {location}",
                    "SUBTITLE": "Find the best realtors in your region based on experiences from other sellers."
                },
                "DETAILS": {
                    "BASEMENT": "With basement",
                    "BATHROOMS": "Number of bathrooms",
                    "BEDROOMS": "Number of bedrooms",
                    "CONDITION": "State of the property",
                    "CONSTRUCTION_YEAR": "Construction year",
                    "GARAGE": "Garage",
                    "GARDEN": "Garden",
                    "GOOD_ENERGY_VALUE": "Good energy score",
                    "LOFT": "With attic",
                    "MATERIAL": "Materials used",
                    "ORIENTATION": "Garden orientation",
                    "PARKING_SPACES": "Outside parking spaces",
                    "QUIET_NEIGHBORHOOD": "Quiet neighborhood",
                    "RENOVATION": "Renovated",
                    "SOLAR_PANELS": "Solar panels",
                    "SPOTS": "{count} cars",
                    "SURFACE": "Habitable surface area",
                    "TITLE": "Property details",
                    "TYPE": "Type"
                },
                "LOCAL_EXPERTS": {
                    "BROKERS_ESTIMATE_CTA": "Receive validation from {resultsSelected} realtors",
                    "HEADER": {
                        "SUBTITLE": "Here are the {resultsSelected} most appropriate experts.",
                        "TITLE": "Have your estimate validated by a local expert"
                    },
                    "SHOW_ALL": "Show all experts"
                },
                "PRICE_EVOLUTION": {
                    "TITLE": "Price evolution"
                },
                "PRICE_PER_SQUARE_METER": {
                    "AVERAGE_LABEL": "Average {price} in {city}",
                    "PERCENTAGE_HIGHER_LABEL": "{percentage}% above average",
                    "PERCENTAGE_LOWER_LABEL": "{percentage}% below average",
                    "TITLE": "Price per m²",
                    "SEGMENT": {
                        "HIGH": "Higher price range",
                        "LOW": "Lower price range",
                        "MEDIUM": "Average price range"
                    }
                },
                "TITLE": "Estimation report",
                "VALUES": {
                    "AVERAGE": "Average",
                    "LOWER_LIMIT": "Lower limit",
                    "RATING_LABEL": "Is this estimation within your expectations?",
                    "TITLE": "HouseMatch estimation",
                    "UPPER_LIMIT": "Upper limit",
                    "HIGH_ESTIMATION_TOOLTIP": "The reliability of this estimate depends on the number of comparable transactions that took place at this location.",
                    "RATING_INFO_PART1": "With the input you provided, we believe this is an accurate representation of the worth of your property.",
                    "RATING_INFO_PART2": "Contact an expert for a free professional estimate.",
                    "RATING_INFO_TOOLTIP": "high",
                    "YOUR_ESTIMATION_INFO": "You indicated that this estimate was not within your expectation. Your estimate: ",
                    "YOUT_ESTIMATION_MODAL_LINK": "Modify"
                },
                "ESTIMATION_FEEDBACK": {
                    "DESIRED_AMOUNT_LABEL": "How much did you have in mind yourself?",
                    "ESTIMATE_OPTIONS_LABEL": "What did you think of this estimate?",
                    "NEGATIVE_TITLE": "The estimate did not meet your expectations",
                    "OPTIONS_RATE": {
                        "ACCURATE": "Accurate",
                        "TOO_HIGH": "Too high",
                        "TOO_LOW": "Much too low",
                        "WAY_TOO_HIGH": "Way too high",
                        "WAY_TOO_LOW": "Way too low"
                    },
                    "POSITIVE_TITLE": "The estimate is within your expectations",
                    "SEND_FEEDBACK_BUTTON": "Send feedback",
                    "SUBMIT_SUCCESS_MESSAGE": "Thank you for your feedback!",
                    "WHY_LABEL": "Why?"
                },
                "ESTIMATION_SUMMARY": {
                    "CALCULATED_NET_PROFIT_INFO": "If you still have an outstanding mortgage, there may be additional fees charged by your bank when you sell.",
                    "ESTIMATION": "Estimate",
                    "GROSS_ESTIMATION_INFO": "Expected gross profit",
                    "GROWTH_AVERAGE_PER_YEAR_INFO": "{growthAveragePerYear}% profit/year",
                    "NET_ESTIMATION_INFO": "Expected net profit",
                    "NOT_CALCULATED_NET_PROFIT_INFO": "In this calculation we do not yet take into account other costs, such as notary fees, or mortgage.",
                    "OPEN_NET_PROFIT_MODAL_BUTTON": "Calculate net capital gain",
                    "OPEN_NET_PROFIT_MODAL_DETAIL_LINK": "Details",
                    "PURCHASE": "Purchase",
                    "RENOVATION": "Renovation",
                    "TITLE": "Your profit"
                },
                "NET_PROFIT": {
                    "BANKING_APP_INFO": "You can find this in your banking app.",
                    "CANCEL_BUTTON": "Cancel",
                    "CONFIRM_BUTTON": "Save",
                    "EXPECTED_NET_CAPITAL_GAIN_LABEL": "Expected net profit",
                    "INTEREST_RATE_LABEL": "Interest rate",
                    "MORTGAGE_RELIEF_LABEL": "Mortgage relief",
                    "MORTGAGE_RELIEF_TOOLTIP": "Notary fees associated with the cancellation of the credit guarantee on your home associated with the mortgage loan taken out to purchase the home.",
                    "OUTSTANDING_AMOUNT_LABEL": "Open amount",
                    "REINVESTMENT_COMPENSATION_LABEL": "Reinvestment fee",
                    "REINVESTMENT_COMPENSATION_TOOLTIP": "Cost associated with the early repayment of your outstanding mortgage loan to the bank that provided you with the loan.",
                    "SALE_FILE_COST_LABEL": "Cost of the sales file",
                    "SALE_FILE_COST_TOOLTIP": "Costs associated with preparing a sales file.",
                    "SELLING_COST_LABEL": "3% sales cost",
                    "SELLING_COST_TOOLTIP": "Estimated cost linked to the sale of your property.",
                    "SUBMIT_SUCCESS_MESSAGE": "Update of your estimation report",
                    "TEXT_INFO": "If you still have an outstanding mortgage, there may be additional fees charged by your bank when you sell.",
                    "TITLE": "Calculate net profit",
                    "TOTAL_COSTS_LABEL": "Total cost"
                }
            }
        },
        "FILTER": {
            "MINIMUM_SCORE": {
                "0": "All scores",
                "5": "5+",
                "6": "6+",
                "7": "7+",
                "8": "8+",
                "9": "9+",
                "10": "10",
                "LABEL": "Score"
            },
            "RELEVANCE": {
                "ALL": "All brokers",
                "RELEVANCE": "Relevant brokers"
            }
        },
        "HEADER": {
            "TITLE": "Brokers"
        },
        "MATCH_MAP": {
            "START_MATCH_FLOW.CTA": "Get a customized proposal"
        },
        "MESSAGES": {
            "START_MATCH_FLOW": {
                "BODY": "Leave your contact details and we will do the rest!",
                "CTA": "Start with HouseMatch",
                "HEAD": "Have realtors contact you <br />with a customized proposal"
            }
        },
        "OVERVIEW": {
            "LOCATIONS": "Offices",
            "NEARBY_REAL_ESTATE_AGENCIES": "Other realtors nearby",
            "OTHER_REAL_ESTATE_AGENCIES": "Other great brokers",
            "RECENT_REVIEWS": "Recent reviews",
            "SHOW_ALL": "Show all reviews",
            "MATCH_PERCENTAGE": "{match}% match",
            "MATCH_TOOLTIP": {
                "TITLE": "The match score is calculated based on the comparability of:",
                "BODY_1": "The broker's <strong>historical portfolio</strong>;",
                "BODY_2": "His <strong>reputation</strong> (mix of number of reviews and satisfaction score);",
                "BODY_3": "<strong>Proximity</strong> of his office location."
            },
            "ABOUT_SUMMARY": {
                "TITLE": "{name} Intro",
                "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": "{name} is a realtor {locationCount, plural, =0{without offices} one{with 1 office} other{with # offices}} whose activities are mainly located in {provinceMainActivity}.",
                "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": "{name} is a realtor {locationCount, plural, =0{without offices} one{with 1 office} other{with # offices}}.",
                "CONTENT_SMOOVED_REVIEWS": " On {total} verified {total, plural, one{1 review} other{# reviews}} they achieve a customer satisfaction score of {score} out of 10, which is {scoreDescription}.",
                "CONTENT_EXTERNAL_REVIEWS": " Moreover, they score an average of {score} out of 5 on Facebook and Google.",
                "LOCATION_CONTENT_INTERNAL": "{locationName} is a realtor office that's part of {groupName}. They achieve a customer satisfaction score of {score} out of {maxScore} based on {amountOfReviews} HouseMatch reviews",
                "LOCATION_CONTENT_EXTERNAL": "{locationName} is a realtor office that's part of {groupName}. They achieve a customer satisfaction score of {score} out of {maxScore} based on {amountOfReviews} Google and Facebook reviews.",
                "LOCATION_CONTENT_NO_REVIEWS": "{locationName} is a realtor office that's part of {groupName}. They currently do not have any reviews yet."
            }
        },
        "SEARCH": {
            "BUTTON": "Search",
            "PLACEHOLDER": "Search here for your address...",
            "HOME_DESCRIPTION_TYPE": "Property type",
            "HOME_DESCRIPTION_TYPE_SHORT": "Type",
            "SURFACE": "Surface",
            "SURFACE_SHORT": "Surf."
        },
        "MATCH_SURVEY": {
            "CONFIRM": {
                "BODY": "We have sent you a confirmation email. Click on the link in the email to publish your review.",
                "HEAD": "Check your email!"
            },
            "ERROR": {
                "BODY": "Something happened while processing your request.",
                "CTA": "Back to HouseMatch",
                "HEAD": "Oh no!"
            },
            "SUCCESS": {
                "BODY": "Your review was published on the HouseMatch page of {realEstateGroup}.",
                "CTA": "View your review",
                "HEAD": "Thank you for your review!"
            }
        },
        "MULTI_SELECT_CONTACT_BANNER": {
            "BROKERS_ESTIMATE_CTA": "Get a price from {numberOfSelectedBrokers} realtors",
            "FAST_AND_FREE": "Fast and free",
            "HOW_DOES_IT_WORK": {
                "CTA": "How does it work?",
                "INSTRUCTIONS_BROKERS_CONTACT": "Get contacted by one or more top realtors",
                "INSTRUCTIONS_CUSTOMER_REVIEW": "Base yourself on verified customer reviews",
                "INSTRUCTIONS_PICK_BROKER": "Choose from all realtors in Belgium"
            },
            "REFINE_RESULTS_CTA": "Refine results"
        },
        "SEARCH_INPUT": {
            "FIND_MATCH": "Find the best-fit realtor",
            "UPDATE": "Update search results"
        },
        "UPSELL": {
            "CTA": "Send proposal to these {count, plural, =0{realtors} one{realtor} other{ # realtors}}",
            "DESCRIPTION1": "The selected realtors will contact you soon.",
            "DESCRIPTION2": "Let these other outstanding realtors from your neigborhood contact you",
            "TITLE": "Message sent!"
        },
        "SCHEDULE": {
            "APPOINTMENT": {
                "BODY": "We'll follow up with you for free all the way to the sale. Let us know when your are available, and we'll give you a call.",
                "HEAD": "Choose when we may contact you",
                "ADDRESS": "Your property"
            }
        },
        "MATCH_SCORE": {
            "GROUP_REVIEWS": "This score is based on the reviews of the real estate group this office belongs to because we don’t have enough reviews for this office.",
            "NO_REVIEWS": "We don’t have enough reviews to calculate a score."
        }
    },
    "EMAIL_PREFERENCES": {
        "FORM": {
            "EXPERTSOPTIN": {
                "LABEL": "Interesting experts in the picture"
            },
            "GLOBALOPTOUT": {
                "LABEL": "I do not longer want to receive emails regarding this estimate"
            },
            "LABEL": "Receive emails about:",
            "NEWFEATURESOPTIN": {
                "LABEL": "New features on HouseMatch"
            },
            "PROPERTYVALUATIONUPDATESOPTIN": {
                "LABEL": "Price updates of my property"
            },
            "REALESTATENEWSOPTIN": {
                "LABEL": "New about the real estate market"
            },
            "PROPERTIESNEARBYOPTIN": {
                "LABEL": "Similar homes for sale nearby"
            }
        },
        "TITLE": "HouseMatch email preferences"
    },
    "COMPARISON": {
        "MUNICIPALITY": {
            "PAGE_TITLE": "Compare brokers and realtors in {zipCode} {city}",
            "HEADER": "Compare <span class=\"u-marked-text-tertiary\">brokers</span> and <span class=\"u-marked-text-primary u-font-style-italic\">realtors</span> in {zipCode} {city}",
            "CHANGE": "Change municipality",
            "SELECTION": {
                "TITLE": "Some of the best realtors in {city}",
                "LABEL": "Are you looking for a reliable real estate agency in {city} ({zipCode}), but want to base your choice on other people's experiences? Below you will find a comparison of real estate agencies in {city}, based on the highest number of reviews in the whole of Belgium."
            },
            "TOP_REALTORS.CTA": "Contact the top 5 brokers",
            "NOT_FOUND": {
                "TITLE": "Didn't find what you where looking for?",
                "LABEL": "No worries! Find the best brokers close to you.",
                "CTA": "Find the best brokers close to me"
            },
            "FREE_ESTIMATION": {
                "TITLE": "Estimate the value of your home for free",
                "LABEL": "With HouseMatch, you can estimate the value of your home for free, online, and accurately. Enter a few details, and within seconds, you’ll have an appraisal that can also be verified for free by the best local agents.",
                "CTA": "Start free online estimation",
                "LINK": "https://app.housematch.be/en/detailed-simulation/address"
            },
            "LOCATIONS": {
                "IN_ZIP_CODE": "These agents have a physical office in { city }",
                "IN_AREA": "These agents operate in the { city } area, but do not have an office there",
                "NON_VERIFIED": "Other agents from { city } that we could not verify but have many Google reviews"
            },
            "TOP_REVIEWS": {
                "TITLE": "Top experiences with brokers in {city}",
                "LABEL": "Find the best broker in your area based on the experiences of other sellers."
            },
            "OTHER_LOCATIONS": " + {amount} {amount, plural, =0{other} one{other} other{other}} {amount, plural, =0{offices} one{office} other{offices}} at this location",
            "NO_RESULT": "No results found at this location",
            "META": {
                "TITLE": "Compare brokers and realtors in {city}",
                "DESCRIPTION": "The best real estate agents and realtors in {city} can be found on HouseMatch. Compare realtors in {city} based on real reviews."
            }
        },
        "OVERVIEW": {
            "PAGE_TITLE": "Find a broker near you",
            "PAGE_DESCRIPTION": "Are you looking for a reliable real estate agency near you, but want to base your choice on other people's experiences? Below you will find a list of comparison of real estate agencies per city, based on the highest number of reviews in the whole of Belgium.",
            "HEADER": "Find a <span class=\"u-marked-text-tertiary\">broker</span> near you",
            "PROVINCE": "Province {province}"
        },
        "ALPHABETICAL": {
            "HEADER": "All <span class=\"u-font-style-italic u-marked-text-primary\">realtors</span> in a row",
            "PAGE_TITLE": "All realtors in a row",
            "PAGE_DESCRIPTION": "Are you looking for a reliable real estate agency near you, but want to base your choice on other people's experiences? Below you will find a list of comparison of real estate agencies in a row, in the whole of Belgium."
        },
        "ALPHABETICAL_LOCATIONS": {
            "HEADER": "All <span class=\"u-font-style-italic u-marked-text-primary\">locations</span> in a row",
            "PAGE_TITLE": "All locations in a row",
            "PAGE_DESCRIPTION": "Are you looking for a reliable real estate agency near you, but want to base your choice on other people's experiences? Below you will find a list of comparison of real estate agencies in a row, in the whole of Belgium."
        }
    },
    "REALTOR": {
        "NOT_FOUND": {
            "TITLE": "Didn't find what you where looking for?",
            "LABEL": "No worries! Find the best brokers close to you.",
            "CTA": "Find the best brokers close to me"
        }
    }
}

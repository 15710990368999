{
    "LOCALIZED_ROUTES": {
        "match-flow-start": "demarrer",
        "match-flow-property": "votre-bien",
        "match-flow-success": "succes",
        "match-flow-contact-info": "vos-donnees",
        "match-flow-estimation": "estimation",
        "match-flow-extra-info": "informations-supplementaires",
        "match-flow-search-result": "agents",
        "overview": "apercu",
        "write-review": "ecrire-un-avis",
        "match-survey-success": "succes",
        "match-survey-confirm": "confirmer",
        "match-survey-error": "erreur",
        "detailed-simulation-flow-extra-info": "informations-supplementaires",
        "detailed-simulation-flow-contact-detail": "coordonnees",
        "detailed-simulation-flow-location": "adress",
        "detailed-simulation-flow-property": "caracteristiques",
        "detailed-simulation-flow-success": "succes",
        "detailed-simulation-flow-status": "etat",
        "detailed-simulation-flow-investment": "investissement",
        "email-preferences": "preferences-emails",
        "comparison": "comparez-immobiliers",
        "realtors": "immobiliers",
        "location": "endroit",
        "reviews": "avis",
        "locations": "endroits"
    },
    "MATCH": {
        "MATCH_FLOW": {
            "PROPERTY": {
                "ABOUT_PROPERTY": {
                    "SURFACE": "La surface habitable (m²):",
                    "AREA": "La surface (m²)",
                    "FLOOR_AREA": "La surface habitable (m²)",
                    "LABEL": "À propos de votre bien",
                    "NO_BEDROOMS": "Nombre de chambres à coucher",
                    "TYPE": "Type"
                },
                "ADDRESS.LABEL": "L'adresse",
                "HEAD": "Votre bien"
            },
            "CONTACT_INFO": {
                "ACCEPT_TERMS_CONDITIONS.LABEL": "J'accepte les | conditions générales | et la | déclaration de confidentialité | de Housematch.",
                "CONTACT": "Données de contact",
                "HEAD": "Vos Données"
            },
            "ESTIMATION": {
                "BODY_RENTAL": "Veuillez indiquer à combien vous estimez le loyer mensuel de {propertyType} en euros, afin que nous puissions vous recommander des courtiers appropriés.",
                "BODY_SALE": "Veuillez indiquer combien vous pensez que {propertyType} vaut en euros, afin que nous puissions vous recommander des agents appropriés.",
                "HEAD": "Estimation",
                "MAX.LABEL": "Maximum",
                "MIN.LABEL": "Au moins",
                "PRICE_RENT": "Prix de location estimé",
                "PRICE_SALE": "Prix de vente estimé"
            },
            "MATCH_FLOW_CONTACT_INFO": "Vos données",
            "MATCH_FLOW_ESTIMATION": "Estimation",
            "MATCH_FLOW_EXTRA_INFO": "Infos supplémentaires",
            "MATCH_FLOW_PROPERTY": "Votre bien",
            "MATCH_FLOW_SEARCH_RESULT": "Agents",
            "EXTRA_INFO": {
                "HEAD": "Plus d'info",
                "REMARKS.LABEL": "Notes pour l'agent"
            },
            "SEARCH_RESULT": {
                "BODY": "Sélectionnez les agents qui peuvent vous contacter.",
                "EDIT": "Affiner",
                "EDIT_TITLE": "Affinez votre recherche",
                "FREE": "Rapide et gratuit",
                "HEAD": "Agents",
                "HOW_DOES_IT_WORK": {
                    "LABEL": "comment ça fonctionne ?",
                    "STEP_1": "Vous choisissez les agents qui peuvent vous contacter",
                    "STEP_2": "Ces agents vous contactent rapidement pour vous proposer une offre sur mesure.",
                    "STEP_3": "Vous choisissez l'agent avec lequel vous vous sentez le plus à l'aise.",
                    "TITLE": "Comment fonctionne HouseMatch ?"
                },
                "NEXT": "Recevoir une proposition de {count} agents",
                "NEXT_SINGLE": "Recevoir une proposition de 1 agent"
            },
            "START": {
                "BODY": "Avec HouseMatch, vous n'avez pas à chercher vous-même un agent immobilier. Nous envoyons vos coordonnées aux agents qui correspondent le mieux à votre profil. Ils vous contacteront ensuite.",
                "HEAD": "Laissez HouseMatch vous aider avec votre"
            },
            "SUBMIT": "Envoyer",
            "SUCCESS": {
                "BODY": "Les agents que vous avez sélectionnés vous contacteront bientôt.",
                "HEAD": "Nous avons bien reçu votre demande."
            }
        },
        "FILTER": {
            "MINIMUM_SCORE": {
                "0": "Tous les scores",
                "5": "5+",
                "6": "6+",
                "7": "7+",
                "8": "8+",
                "9": "9+",
                "10": "10",
                "LABEL": "Score"
            },
            "RELEVANCE": {
                "ALL": "Tous les agents",
                "RELEVANCE": "Les agents pertinents"
            }
        },
        "HEADER": {
            "TITLE": "Agents"
        },
        "MATCH_MAP": {
            "START_MATCH_FLOW.CTA": "Obtenez une proposition sur mesure"
        },
        "MESSAGES": {
            "START_MATCH_FLOW": {
                "BODY": "Laissez vos coordonnées et nous ferons le reste !",
                "CTA": "Commencez avec HouseMatch",
                "HEAD": "Laissez les agents vous contacter <br />avec une proposition sur mesure"
            }
        },
        "OVERVIEW": {
            "LOCATIONS": "Bureaux",
            "NEARBY_REAL_ESTATE_AGENCIES": "Autres agents dans votre région",
            "OTHER_REAL_ESTATE_AGENCIES": "Autres bons agents",
            "RECENT_REVIEWS": "Avis récents",
            "SHOW_ALL": "Afficher tous les avis",
            "MATCH_PERCENTAGE": "{match}% match",
            "MATCH_TOOLTIP": {
                "TITLE": "Le score match est calculé en fonction de la:",
                "BODY_1": "Comparabilité du <strong>portefeuille historique</strong> de l'agence;",
                "BODY_2": "De sa <strong>réputation</strong> (déterminée sur la base du nombre d'avis et du score de satisfaction);",
                "BODY_3": "De la <strong>proximité</strong> de l'agence."
            },
            "ABOUT_SUMMARY": {
                "TITLE": "Introduction {name}",
                "CONTENT_WITH_PROVINCE_MAIN_ACTIVITY": "{name} est une agence {locationCount, plural, =0{sans bureaux} one{avec 1 bureau} other{avec # bureaux}} dont les activités sont principalement situées dans la Province du {provinceMainActivity}.",
                "CONTENT_WITHOUT_PROVINCE_MAIN_ACTIVITY": "{name} est une agence {locationCount, plural, =0{sans bureau} one{avec 1 bureau} other{avec # bureaux}}.",
                "CONTENT_SMOOVED_REVIEWS": " Sur {total, plural, one{1 avis vérifié} other{# avis vérifiés}}, ils obtiennent une note de satisfaction client de {score} sur 10, ce qui est {scoreDescription}.",
                "CONTENT_EXTERNAL_REVIEWS": " En outre, ils obtiennent une note moyenne de {score} sur 5 sur Facebook et Google.",
                "LOCATION_CONTENT_INTERNAL": "{locationName} est une agence immobilière faisant partie de {groupName}. Ils atteignent un score de satisfaction client de {score} sur {maxScore} basé sur {amountOfReviews} avis HouseMatch",
                "LOCATION_CONTENT_EXTERNAL": "{locationName} est une agence immobilière faisant partie de {groupName}. Ils atteignent un score de satisfaction client de {score} sur {maxScore} basé sur {amountOfReviews} avis sur Google et Facebook.",
                "LOCATION_CONTENT_NO_REVIEWS": "{locationName} est une agence immobilière faisant partie de {groupName}. Ils n'ont actuellement aucune évaluation."
            }
        },
        "SEARCH": {
            "BUTTON": "Rechercher",
            "PLACEHOLDER": "Recherchez votre adresse ici...",
            "HOME_DESCRIPTION_TYPE": "Type d'immeuble",
            "HOME_DESCRIPTION_TYPE_SHORT": "Type",
            "SURFACE": "Surface",
            "SURFACE_SHORT": "Surf."
        },
        "MATCH_SURVEY": {
            "CONFIRM": {
                "BODY": "Nous vous avons envoyé un e-mail de confirmation. Cliquez sur le lien dans ce mail pour publier votre avis.",
                "HEAD": "Vérifiez votre e-mail !"
            },
            "ERROR": {
                "BODY": "Un problème est survenu lors du traitement de votre demande.",
                "CTA": "Retournez au site de HouseMatch",
                "HEAD": "O non!"
            },
            "SUCCESS": {
                "BODY": "Votre avis a été publié sur la page HouseMatch de {realEstateGroup}.",
                "CTA": "Afficher votre avis",
                "HEAD": "Merci pour votre avis!"
            }
        },
        "MULTI_SELECT_CONTACT_BANNER": {
            "BROKERS_ESTIMATE_CTA": "Obtenir le prix de {numberOfSelectedBrokers} agents immobiliers",
            "FAST_AND_FREE": "Rapide et gratuit",
            "HOW_DOES_IT_WORK": {
                "CTA": "Comment ça fonctionne?",
                "INSTRUCTIONS_BROKERS_CONTACT": "Laissez-vous contacter par un ou plusieurs agents immobilier de qualité",
                "INSTRUCTIONS_CUSTOMER_REVIEW": "Basez-vous sur des avis vérifiés de clients ",
                "INSTRUCTIONS_PICK_BROKER": "Choisissez parmi tous les agents immobiliers en Belgique"
            },
            "REFINE_RESULTS_CTA": "Affinez les résults"
        },
        "SEARCH_INPUT": {
            "FIND_MATCH": "Trouvez l'agent le plus adapté",
            "UPDATE": "Mettre à jour les résultats"
        },
        "UPSELL": {
            "CTA": "Envoyez également une demande de prix à ce(s) {count, plural, =0{agents} one{agent} other{ # agents}}",
            "DESCRIPTION1": "Les agents sélectionnés vous contacteront bientôt.",
            "DESCRIPTION2": "Laissez ces autres agents immobiliers exceptionnels de votre quartier vous contacter.",
            "TITLE": "Message transmis!"
        },
        "DETAILED_SIMULATION": {
            "FLOW": {
                "CONSENT": "J'autorise HouseMatch à envoyer des e-mails dans le cadre de l'obtention d'une estimation professionnelle de mon bien et de l'obtention d'une assistance professionnelle pour la vente d'un bien et j'accepte par <a class=\"u-color-primary\" href=\"https://housematch.be/fr/privacy/\" target=\"_blank\">la présente la déclaration relative au traitement et au transfert de données</a>.",
                "CONTACT_DETAIL": {
                    "HEAD": "Coordonnées",
                    "INTRO": "Votre numéro de téléphone et votre adresse mail seront utilisés pour accéder à votre estimation personnelle. Veillez donc à ce que ces informations soient <u>correctes</u>.",
                    "REALTOR_OUTREACH": {
                        "FREE_QUOTE_VALIDATION": "Faites valider votre estimation gratuitement",
                        "THREE_BEST_BROKERS_SUGGESTION": "Voici les 3 agences immobilières les plus taillées pour valider votre estimation numérique.",
                        "CHECK_BROKERS_CONTACT_ME": "Ces agents peuvent me contacter pour une validation gratuite."
                    }
                },
                "DETAILED_SIMULATION_FLOW_CONTACT_DETAIL": "Coordonnées",
                "DETAILED_SIMULATION_FLOW_EXTRA_INFO": "Suppléments",
                "DETAILED_SIMULATION_FLOW_LOCATION": "Adresse",
                "DETAILED_SIMULATION_FLOW_PROPERTY": "Caractéristiques",
                "DETAILED_SIMULATION_FLOW_INVESTMENT": "Investissement",
                "DETAILED_SIMULATION_FLOW_STATUS": "L'état du bien",
                "DETAILED_SIMULATION_FLOW_SUCCESS": "Résultat",
                "EXTRA_INFO": {
                    "GARDEN": {
                        "LABEL": "Jardin",
                        "ORIENTATION": {
                            "EAST": "Est",
                            "LABEL": "Orientation",
                            "NORTH": "Nord",
                            "NORTH_EAST": "Nord-est",
                            "NORTH_WEST": "Nord-ouest",
                            "SOUTH": "Sud",
                            "SOUTH_EAST": "Sud-est",
                            "SOUTH_WEST": "Sud-ouest",
                            "WEST": "Ouest"
                        }
                    },
                    "HEAD": "Suppléments - optionnel",
                    "OTHER": {
                        "GOOD_ENERGY_VALUE": "Bonne valeur énergétique",
                        "LABEL": "Autre",
                        "QUIET_NEIGHBORHOOD": "Quartier calme",
                        "SOLAR_PANELS": "Panneaux solaires"
                    },
                    "SPACES": {
                        "BASEMENT": "Cave",
                        "GARAGE": "Places de garage",
                        "LABEL": "Endroits",
                        "LOFT": "Grenier",
                        "PARKING_SPACES": "Places de parking extérieures"
                    }
                },
                "LOCATION": {
                    "HEAD": "Adresse"
                },
                "PROPERTY": {
                    "BATHROOMS.LABEL": "Nombre de salles de bain",
                    "BEDROOMS.LABEL": "Nombre de chambres à coucher",
                    "GARDEN_AREA.LABEL": "Surface du jardin (m²)",
                    "HAS_GARDEN.LABEL": "Avec jardin",
                    "TERRACE_AREA.LABEL": "Surface de la terrasse (m²)",
                    "HAS_TERRACE.LABEL": "Avec terrasse",
                    "HEAD": "Caractéristiques",
                    "LIVING_AREA.LABEL": "Surface habitable (m²)",
                    "TYPE.LABEL": "Type de maison"
                },
                "INVESTMENT": {
                    "HEAD": "Investissement",
                    "PURCHASE_PRICE": "Prix d'achat/construction",
                    "PURCHASE_YEAR": "Année d'achat",
                    "RENOVATED": "J'ai rénové",
                    "RENOVATION_PRICE": "Montant des rénovations",
                    "RENOVATION_YEAR": "Année de rénovation"
                },
                "STATUS": {
                    "CONSTRUCTION_MATERIAL.LABEL": "Matériaux utilisés",
                    "CONSTRUCTION_YEAR.LABEL": "Année de construction",
                    "HEAD": "État du bien",
                    "PROPERTY_STATUS.LABEL": "État du bien",
                    "WHEN.OPTIONS": {
                        "CONSTRUCTION_MATERIAL": {
                            "BASIC": "Base",
                            "PREMIUM": "Premium",
                            "QUALITATIVE": "Qualité"
                        },
                        "STATUS": {
                            "NEW_CONSTRUCTION": "Nouvelle construction",
                            "EXCELLENT": "Excéllent",
                            "GOOD": "Bon",
                            "RENOVATED": "Renové",
                            "TO_REFRESH": "À rafraîchir",
                            "TO_RENOVATE": "À rénover",
                            "TO_REBUILD": "À reconstruire"
                        }
                    }
                },
                "SUBMIT": "Envoyer un devis gratuit",
                "SUBMIT_LOW_SALE_INTENT": "Envoyer un devis gratuit",
                "SUBMIT_HIGH_SALE_INTENT_CONSENT": "Envoyer un devis gratuit avec validation",
                "SUBMIT_HIGH_SALE_INTENT_NO_CONSENT": "Envoyer un devis gratuit sans validation",
                "SUCCESS": {
                    "HEAD": "Estimation en route",
                    "RESULTS": {
                        "CTA": "Contacter",
                        "LABEL": "Pour une estimation vraiment professionnelle, nous donnons déjà une note élevée aux agents immobiliers ci-dessous :",
                        "NO_DATA": "Aucun résultat trouvé"
                    },
                    "SUBHEAD": "Dans quelques minutes, vous recevrez votre estimation à l'adresse suivante : {email} ",
                    "MESSAGES": {
                        "TEXT": "Sélectionnez les agents immobiliers susceptibles de vous contacter.",
                        "TITLE": "Nous attribuons une bonne note à ces experts"
                    },
                    "HIGH_SALE_INTENT": {
                        "TITLE": "Ces agents immobiliers vous contacteront bientôt"
                    }
                }
            },
            "REPORT": {
                "BOTTOM_BANNER": {
                    "CTA": "Validation gratuite par un expert local",
                    "EFFICIENT": "Efficace",
                    "LOCAL": "Locale",
                    "FREE": "Gratuite",
                    "HOW_DOES_IT_WORK": {
                        "LABEL": "Comment ça fonctionne ?",
                        "STEP_1": "Obtenir un devis validé gratuitement par des experts locaux",
                        "STEP_2": "Choisissez parmi tous les agents immobiliers de votre région",
                        "STEP_3": "Choisissez en fonction des avis vérifiés des clients"
                    },
                    "INDEPENDENT": "Indépendant",
                    "NON_COMMITTAL": "Sans obligation",
                    "TRUSTWORTHY": "Fiable"
                },
                "TOP_EXPERIENCES": {
                    "TITLE": "Laissez-vous guider par les meilleures expériences récentes à {location}",
                    "SUBTITLE": "Trouvez le meilleur agent immobilier de votre région en vous basant sur l'expérience d'autres vendeurs."
                },
                "DETAILS": {
                    "BASEMENT": "Avec cave",
                    "BATHROOMS": "Nombre de salles de bains",
                    "BEDROOMS": "Nombre de chambres à coucher",
                    "CONDITION": "État du bien",
                    "CONSTRUCTION_YEAR": "Année de construction",
                    "GARAGE": "Garage",
                    "GARDEN": "Jardin",
                    "GOOD_ENERGY_VALUE": "Bon score énergétique",
                    "LOFT": "Avec grenier",
                    "MATERIAL": "Matériaux utilisés",
                    "ORIENTATION": "Orientation du jardin",
                    "PARKING_SPACES": "Places de stationnement extérieures",
                    "QUIET_NEIGHBORHOOD": "Quartier calme",
                    "RENOVATION": "Rénové",
                    "SOLAR_PANELS": "Panneaux solaires",
                    "SPOTS": "{count} voitures",
                    "SURFACE": "Superficie habitable",
                    "TITLE": "Détails du bien",
                    "TYPE": "Type"
                },
                "LOCAL_EXPERTS": {
                    "BROKERS_ESTIMATE_CTA": "Obtenir la validation de {resultsSelected} agents immobiliers",
                    "HEADER": {
                        "SUBTITLE": "Voici les {resultsSelected} experts les plus appropriés.",
                        "TITLE": "Faites valider votre devis par un expert local"
                    },
                    "SHOW_ALL": "Afficher tous les experts"
                },
                "PRICE_EVOLUTION": {
                    "TITLE": "Évolution du prix"
                },
                "PRICE_PER_SQUARE_METER": {
                    "AVERAGE_LABEL": "Moyen {price} à {city}",
                    "PERCENTAGE_HIGHER_LABEL": "{percentage}% plus élevé que la moyenne",
                    "PERCENTAGE_LOWER_LABEL": "{percentage}% plus bas que la moyenne",
                    "TITLE": "Prix par m²",
                    "SEGMENT": {
                        "HIGH": "Gamme de prix plus élevée",
                        "LOW": "Gamme de prix plus basse",
                        "MEDIUM": "Gamme de prix moyenne"
                    }
                },
                "TITLE": "Rapport d'estimation",
                "VALUES": {
                    "AVERAGE": "Moyenne",
                    "LOWER_LIMIT": "Limite inférieure",
                    "RATING_LABEL": "Cette estimation correspond-elle à vos attentes ?",
                    "TITLE": "Estimation HouseMatch",
                    "UPPER_LIMIT": "Limite supérieure",
                    "HIGH_ESTIMATION_TOOLTIP": "La fiabilité de cette estimation dépend du nombre de transactions comparables qui ont eu lieu à cet endroit.",
                    "RATING_INFO_PART1": "Avec les informations que vous avez fournies, nous pensons que c'est une représentation précise de la valeur de votre propriété.",
                    "RATING_INFO_PART2": "Contactez un expert pour une estimation professionnel gratuit.",
                    "RATING_INFO_TOOLTIP": "haut",
                    "YOUR_ESTIMATION_INFO": "Vous avez indiqué que cette estimation ne correspondait pas à vos attentes. Votre estimation : ",
                    "YOUT_ESTIMATION_MODAL_LINK": "Modifiez"
                },
                "ESTIMATION_FEEDBACK": {
                    "DESIRED_AMOUNT_LABEL": "Quel montant aviez-vous en tête ?",
                    "ESTIMATE_OPTIONS_LABEL": "Que pensez-vous de cette estimation ?",
                    "NEGATIVE_TITLE": "L' estimation ne correspond pas à vos attentes",
                    "OPTIONS_RATE": {
                        "ACCURATE": "Précise",
                        "TOO_HIGH": "Trop élevée",
                        "TOO_LOW": "Beaucoup trop bas",
                        "WAY_TOO_HIGH": "Beaucoup trop élevée",
                        "WAY_TOO_LOW": "Beaucoup trop bas"
                    },
                    "POSITIVE_TITLE": "L'estimation est conforme à vos attentes",
                    "SEND_FEEDBACK_BUTTON": "Envoyez votre feedback",
                    "SUBMIT_SUCCESS_MESSAGE": "Merci pour votre feedback !",
                    "WHY_LABEL": "Pourqoui?"
                },
                "ESTIMATION_SUMMARY": {
                    "CALCULATED_NET_PROFIT_INFO": "Si vous avez encore un prêt hypothécaire en cours, votre banque peut vous facturer des frais supplémentaires au moment de la vente.",
                    "ESTIMATION": "Estimation",
                    "GROSS_ESTIMATION_INFO": "Plus-value brute attendue",
                    "GROWTH_AVERAGE_PER_YEAR_INFO": "{growthAveragePerYear}% de valeur ajoutée/an",
                    "NET_ESTIMATION_INFO": "Plus-value nette attendue",
                    "NOT_CALCULATED_NET_PROFIT_INFO": "Dans ce calcul, nous ne prenons pas encore en compte d'autres coûts, tels que les frais de notaire ou l'hypothèque.",
                    "OPEN_NET_PROFIT_MODAL_BUTTON": "Calculez la plus-value nette",
                    "OPEN_NET_PROFIT_MODAL_DETAIL_LINK": "Détail",
                    "PURCHASE": "Achat",
                    "RENOVATION": "Rénovation",
                    "TITLE": "Votre valeur ajoutée"
                },
                "NET_PROFIT": {
                    "BANKING_APP_INFO": "Vous trouverez cela dans votre application bancaire.",
                    "CANCEL_BUTTON": "Annuler",
                    "CONFIRM_BUTTON": "Sauvegarder",
                    "EXPECTED_NET_CAPITAL_GAIN_LABEL": "Plus-value nette attendue",
                    "INTEREST_RATE_LABEL": "Taux d'intérêt",
                    "MORTGAGE_RELIEF_LABEL": "Allégement de la dette hypothécaire",
                    "MORTGAGE_RELIEF_TOOLTIP": "Frais de notaire liés à l'annulation de la garantie de crédit sur votre logement associée au prêt hypothécaire contracté pour l'achat du logement.",
                    "OUTSTANDING_AMOUNT_LABEL": "Montant restant à payer",
                    "REINVESTMENT_COMPENSATION_LABEL": "Frais de réinvestissement",
                    "REINVESTMENT_COMPENSATION_TOOLTIP": "Coût lié au remboursement anticipé de votre prêt hypothécaire en cours à la banque qui vous a accordé le prêt.",
                    "SALE_FILE_COST_LABEL": "Coût du dossier de vente",
                    "SALE_FILE_COST_TOOLTIP": "Coûts liés à la préparation d'un dossier de vente.",
                    "SELLING_COST_LABEL": "3% coût des ventes",
                    "SELLING_COST_TOOLTIP": "Estimation des coûts liés à la vente du bien.",
                    "SUBMIT_SUCCESS_MESSAGE": "Mise à jour du rapport d'estimation.",
                    "TEXT_INFO": "Si vous avez encore un prêt hypothécaire en cours, votre banque peut vous facturer des frais supplémentaires au moment de la vente.",
                    "TITLE": "Calculez la plus-value nette",
                    "TOTAL_COSTS_LABEL": "Coût total"
                }
            }
        },
        "SCHEDULE": {
            "APPOINTMENT": {
                "BODY": "Nous vous suivrons gratuitement jusqu'à la vente. Faites-nous savoir quand cela vous convient et nous vous appellerons.",
                "HEAD": "Choisissez quand nous pouvons vous contacter",
                "ADDRESS": "Votre bien"
            }
        },
        "MATCH_SCORE": {
            "GROUP_REVIEWS": "Ce score est basé sur les avis du groupe immobilier auquel ce bureau appartient car nous n'avons pas assez d'avis pour ce bureau.",
            "NO_REVIEWS": "Nous n'avons pas assez d'avis pour calculer un score."
        }
    },
    "EMAIL_PREFERENCES": {
        "FORM": {
            "EXPERTSOPTIN": {
                "LABEL": "Interesting experts in the picture"
            },
            "GLOBALOPTOUT": {
                "LABEL": "Je ne veux plus recevoir de courriels concernant cette estimation"
            },
            "LABEL": "Recevoir des emails sur :",
            "NEWFEATURESOPTIN": {
                "LABEL": "Nouvelles fonctionnalités sur HouseMatch"
            },
            "PROPERTYVALUATIONUPDATESOPTIN": {
                "LABEL": "Mise à jour du prix de mon bien"
            },
            "REALESTATENEWSOPTIN": {
                "LABEL": "Actualités du marché immobilier"
            },
            "PROPERTIESNEARBYOPTIN": {
                "LABEL": "Propriétés similaires à vendre dans les environs"
            }
        },
        "TITLE": "Préférences e-mail de HouseMatch"
    },
    "COMPARISON": {
        "MUNICIPALITY": {
            "PAGE_TITLE": "Comparez les courtiers et les agents immobiliers en {zipCode} {city}",
            "HEADER": "Comparez les <span class=\"u-marked-text-tertiary\">courtiers</span> et les <span class=\"u-marked-text-primary u-font-style-italic\">agents immobiliers</span> en {zipCode} {city}",
            "CHANGE": "Changement de municipalité",
            "SELECTION": {
                "TITLE": "Quelques-unes des meilleures agences immobilières de {city}",
                "LABEL": "Vous êtes à la recherche d'une agence immobilière fiable à {city} ({zipCode}), mais vous souhaitez baser votre choix sur l'expérience d'autres personnes ? Vous trouverez ci-dessous une comparaison des agences immobilières à {city}, basée sur le plus grand nombre d'avis en Belgique."
            },
            "TOP_REALTORS.CTA": "Contacter les 5 premiers courtiers",
            "NOT_FOUND": {
                "TITLE": "Vous n'avez pas trouvé ce que vous cherchiez ?",
                "LABEL": "Pas de soucis ! Trouvez les meilleurs courtiers près de chez vous.",
                "CTA": "Trouvez les meilleurs courtiers près de chez moi"
            },
            "FREE_ESTIMATION": {
                "TITLE": "Estimez gratuitement la valeur de votre maison",
                "LABEL": "Avec HouseMatch, vous pouvez estimer gratuitement, en ligne et de manière précise, la valeur de votre maison. Entrez quelques détails et en quelques secondes, vous aurez une évaluation qui peut également être vérifiée gratuitement par les meilleurs agents locaux.",
                "CTA": "Commencez l'estimation gratuite en ligne",
                "LINK": "https://app.housematch.be/fr/detailed-simulation/adress"
            },
            "LOCATIONS": {
                "IN_ZIP_CODE": "Ces agents ont un bureau physique à { city }",
                "IN_AREA": "Ces agents opèrent dans la région de { city }, sans y avoir de bureau",
                "NON_VERIFIED": "Autres agents de { city } que nous n'avons pas pu vérifier mais qui ont de nombreux avis Google"
            },
            "TOP_REVIEWS": {
                "TITLE": "Principales expériences avec des agents immobiliers dans {city}",
                "LABEL": "Trouvez le meilleur agent immobilier de votre région en vous basant sur l'expérience d'autres vendeurs."
            },
            "OTHER_LOCATIONS": " + {amount} {amount, plural, =0{autres} one{autre} other{autres}} {amount, plural, =0{bureaux} one{bureau} other{bureaux}} à cet endroit",
            "NO_RESULT": "Aucun résultat n'a été trouvé à cet endroit",
            "META": {
                "TITLE": "Comparez les agents immobiliers et les agences immobilières à { city }",
                "DESCRIPTION": "Les meilleurs agences immobilières de { city } sont sur HouseMatch. Comparez les agents immobiliers à { city } sur la base de vrais avis."
            }
        },
        "OVERVIEW": {
            "PAGE_TITLE": "Trouver un courtier près de chez vous",
            "PAGE_DESCRIPTION": "Vous êtes à la recherche d'une agence immobilière fiable près de chez vous, mais vous souhaitez baser votre choix sur l'expérience d'autres personnes ? Vous trouverez ci-dessous une liste comparative des agences immobilières par ville, basée sur le plus grand nombre d'avis dans toute la Belgique.",
            "HEADER": "Trouver un <span class=\"u-marked-text-tertiary\">courtier</span> près de chez vous",
            "PROVINCE": "La province {province}"
        },
        "ALPHABETICAL": {
            "HEADER": "Tous les <span class=\"u-font-style-italic u-marked-text-primary\">agents immobiliers</span> en rang d'oignons",
            "PAGE_TITLE": "Tous les agents immobiliers en rang d'oignons",
            "PAGE_DESCRIPTION": "Vous êtes à la recherche d'une agence immobilière fiable près de chez vous, mais vous souhaitez baser votre choix sur l'expérience d'autres personnes ? Vous trouverez ci-dessous une liste de comparaison d'agences immobilières en ligne, dans toute la Belgique."
        },
        "ALPHABETICAL_LOCATIONS": {
            "HEADER": "Tous les <span class=\"u-font-style-italic u-marked-text-primary\">endroits immobiliers</span> en rang d'oignons",
            "PAGE_TITLE": "Tous les endroits immobiliers en rang d'oignons",
            "PAGE_DESCRIPTION": "Vous êtes à la recherche d'une agence immobilière fiable près de chez vous, mais vous souhaitez baser votre choix sur l'expérience d'autres personnes ? Vous trouverez ci-dessous une liste de comparaison d'agences immobilières en ligne, dans toute la Belgique."
        }
    },
    "REALTOR": {
        "NOT_FOUND": {
            "TITLE": "Vous n'avez pas trouvé ce que vous cherchiez ?",
            "LABEL": "Pas de soucis ! Trouvez les meilleurs courtiers près de chez vous.",
            "CTA": "Trouvez des courtiers proches"
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtils } from '../utils';

// Custom number pipe.
// Default: Show 1 decimal, dot as separator ( en-US locale )
@Pipe({
    name: 'digit',
})
export class DigitPipe implements PipeTransform {
    transform(value: number): string {
        return NumberUtils.decimalLabel(value);
    }
}
